import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const EditSedeJudicial = () => {
  const { id } = useParams();
  const [sedejudi, setSedeJudicial] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSedeJudicial = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}sede_judicialget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setSedeJudicial(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching distrito judicial:", error);
        setLoading(false);
      }
    };

    fetchSedeJudicial();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);
    try {
      await axios.put(`${window.BACKEND_URL}sede_judicialput/${id}`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      window.history.back();
    } catch (error) {
      console.error("Error updating titulo:", error);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Editar sede</h5>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="distrito" className="form-label">
              Distrito
            </label>
            <input
              type="text"
              id="distrito"
              className="form-control"
              name="distrito"
              value={sedejudi.distrito}
              disabled
            />
            <br />
            <label htmlFor="distrito" className="form-label">
              Sede
            </label>

            <input
              type="text"
              id="sede"
              className="form-control"
              name="sede"
              defaultValue={sedejudi.sede}
            />
          </div>

          <label htmlFor="distrito" className="form-label">
            Numero
          </label>

          <input
            type="number"
            id="numero"
            name="numero"
            className="form-control"
            defaultValue={sedejudi.numero}
            inputMode="numeric"
            pattern="\d*"
            max="9999"
            min="0"
            required
          />

          <br />

          <button className="btn btn-primary  me-5" type="submit">
            Guardar
          </button>

          <Link to="/sede_judicial" className="btn btn-secondary">
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EditSedeJudicial;
