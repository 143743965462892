import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const Editperiodo = () => {
  const { id } = useParams();

  const [fechaIn, setFechaIn] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [estado, setEstado] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPeriodoById = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}periodoget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        const periodo = response.data;
        setFechaIn(new Date(periodo.fecha_in));
        setFechaFin(new Date(periodo.fecha_fin));
        setEstado(periodo.estado);
      } catch (error) {
        console.error(error);
        setError("Error al cargar el periodo académico");
      } finally {
        setLoading(false);
      }
    };

    fetchPeriodoById();
  }, [id]);

  const handleCancel = () => {
    window.history.back();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${window.BACKEND_URL}periodoput/${id}`,
        {
          fecha_in: new Date(fechaIn), // Convertir a Date
          fecha_fin: new Date(fechaFin), // Convertir a Date
          estado: estado,
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      alert("Los cambios se han guardado correctamente");
      window.history.back();
    } catch (error) {
      console.error(error);
      setError("Error al guardar los cambios");
    }
  };
  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Editperiodo card</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSave}>
            <div className="mb-3">
              <label htmlFor="fechaInicio" className="form-label">
                Fecha inicio
              </label>
              <DatePicker
                selected={fechaIn}
                onChange={(date) => setFechaIn(date)}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fechaFin" className="form-label">
                Fecha fin
              </label>
              <DatePicker
                selected={fechaFin}
                onChange={(date) => setFechaFin(date)}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                type="checkbox"
                id="estado"
                checked={estado}
                onChange={(e) => setEstado(e.target.checked)}
              />
              <label htmlFor="estado" className="check-label">
                Estado
              </label>
            </div>
            <button type="submit" className="btn btn-primary me-2">
              Guardar
            </button>
            <Link
              to="/periodo-academico"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default Editperiodo;
