import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.min.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Addcurso = () => {
  const [users, setUsers] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [titlos, setTitulos] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [sedecursos, setsedeCurso] = useState([]);
  const [imagenPreview, setImagenPreview] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activo, setActivo] = useState(false);

  const handleImagenChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagenPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagenPreview("");
    }
  };
  const handleActivoChange = () => {
    setActivo(!activo); // Invertir el estado actual
  };
  const fetchUsers = () => {
    axios
      .get(`${window.BACKEND_URL}users?role=Docente`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const gettitulos = () => {
    axios
      .get(`${window.BACKEND_URL}titulo`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setTitulos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching titulos:", error);
      });
  };

  const fetchTurno = () => {
    axios
      .get(`${window.BACKEND_URL}turno`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setTurnos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching turno:", error);
      });
  };

  const fetchPeriodo = () => {
    axios
      .get(`${window.BACKEND_URL}periodo`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPeriodos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Periodo:", error);
      });
  };

  const getSedecursos = () => {
    axios
      .get(`${window.BACKEND_URL}sedelist`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setsedeCurso(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Periodo:", error);
      });
  };
  useEffect(() => {
    fetchUsers();
    fetchTurno();
    fetchPeriodo();
    gettitulos();
    getSedecursos();
    // Initialize Select2 after data is fetched
    $("#select2").select2();

    $("#select1").select2();
    $("#select3").select2();
    $("#select4").select2();
    $("#select5").select2();
  }, []);
  const handleYearChange = (date) => {
    setSelectedYear(date);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("imagen", event.target.imagen.files[0]); // Agrega la imagen al FormData
    formData.append("titulo_id", event.target.titulo_id.value);
    formData.append("aula", event.target.aula.value);
    formData.append("seccion", event.target.seccion.value);
    formData.append("turno_id", event.target.turno_id.value);
    formData.append("anio", selectedYear ? selectedYear.getFullYear() : null);
    formData.append("periodo", event.target.periodo.value);
    formData.append("docente_id", event.target.docente_id.value);
    formData.append("sedecurso_id", event.target.sedecurso_id.value);
    formData.append("periodoa_id", event.target.periodoa_id.value);
    formData.append("activo", activo);

    axios
      .post(`${window.BACKEND_URL}cursospost`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Curso agregado:", response.data);
        window.history.back();
      })
      .catch((error) => {
        console.error("Error al agregar curso:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Agregar Curso</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="activoCheckbox">Activo:</label>
              <input
                type="checkbox"
                id="activoCheckbox"
                checked={activo}
                onChange={handleActivoChange}
                className="form-check-input"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="tituloSelect">
                Seleccione un titulo del curso:
              </label>
              <select
                id="select3"
                className="form-control"
                name="titulo_id"
                required
              >
                <option value="">Seleccione un titulo</option>
                {titlos.map((titulo) => (
                  <option key={titulo._id} value={titulo._id}>
                    {titulo.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="aula">Aula:</label>
              <input
                type="text"
                id="aula"
                className="form-control"
                name="aula"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="aula">Seccion:</label>
              <input
                type="text"
                id="seccion"
                className="form-control"
                name="seccion"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="turnoSelect">Seleccione un turno:</label>
              <select id="select1" className="form-control" name="turno_id">
                <option value="">Seleccione un turno</option>
                {turnos.map((turno) => (
                  <option key={turno._id} value={turno._id}>
                    {turno.turno}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="anio">Año:</label>
              <br />
              <DatePicker
                id="anio"
                name="anio"
                className="form-control"
                selected={selectedYear}
                onChange={handleYearChange}
                dateFormat="yyyy"
                showYearPicker
                placeholderText="Seleccione un año"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="turnoSelect">Seleccione un Perido:</label>
              <select id="select4" className="form-control" name="periodo">
                <option value="">Seleccione un Periodo</option>
                <option value="SEMESTRE IMPAR">SEMESTRE IMPAR</option>
                <option value="SEMESTRE PAR">SEMESTRE PAR</option>
                <option value="VERANO">VERANO</option>
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="userSelect">Seleccione un docente:</label>
              <select
                id="select2"
                className="form-control"
                name="docente_id"
                required
              >
                <option value="">Seleccione un docente</option>
                {users
                  .filter((user) => user.role === "Docente")
                  .map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="userSelect">
                Seleccione una sede para el cursos:
              </label>
              <select
                id="select5"
                className="form-control"
                name="sedecurso_id"
                required
              >
                <option value="">Seleccione la sede del cursos</option>
                {sedecursos.map((sedecurso) => (
                  <option key={sedecurso._id} value={sedecurso._id}>
                    {sedecurso.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="periodoSelect">Seleccione un periodo:</label>
              <select
                id="periodoSelect"
                className="form-control"
                name="periodoa_id"
              >
                <option value="">Seleccione un periodo</option>
                {periodos
                  .filter((periodo) => periodo.estado === true)
                  .map((periodo) => (
                    <option key={periodo._id} value={periodo._id}>
                      {new Date(periodo.fecha_fin).toLocaleDateString()} -{" "}
                      {new Date(periodo.fecha_in).toLocaleDateString()}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="imagen">Imagen:</label>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="imagen"
                  name="imagen"
                  onChange={handleImagenChange}
                />
              </div>
              {imagenPreview && (
                <img
                  src={imagenPreview}
                  className="mt-2 img-thumbnail"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                  alt="Vista previa de la imagen"
                />
              )}
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Agregando..." : "Agregar"}
            </button>
            <button
              type="button"
              className="btn btn-secondary mx-2"
              onClick={() => window.history.back()}
            >
              Cancelar
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Addcurso;
