import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const EditSalas = () => {
  const { id } = useParams();

  const [salas, setSalas] = useState([]); // Aquí se almacenará el tipo de pretensión

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchsalasById = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}salasget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        setSalas(response.data);
        console.log("data", response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchsalasById();
  }, [id]);

  const handleCancel = () => {
    window.history.back();
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);

    try {
      await axios.put(`${window.BACKEND_URL}salasput/${id}`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      alert("Los cambios se han guardado correctamente");
      window.history.back();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Editar Sala</h5>
        </div>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <div className="card-body">
            <form onSubmit={handleSave}>
              <div className="mb-3">
                <label htmlFor="materia" className="form-label">
                  Materia
                </label>

                <input
                  type="text"
                  value={salas[0]?.materia}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="mb-3">
                <label htmlFor="sede" className="form-label">
                  Sede
                </label>

                <input
                  type="text"
                  value={salas[0]?.datasede[0]?.sede}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor="nombre" className="form-label">
                  Nombre
                </label>

                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  className="form-control"
                  defaultValue={salas[0]?.nombre}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary me-2">
                Guardar
              </button>
              <Link
                to="/salas"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancelar
              </Link>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default EditSalas;
