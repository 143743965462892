import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const EditCuaderno = () => {
  const { id } = useParams();
  const [nombreCuaderno, setNombreCuaderno] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCuaderno = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}tipocuadernoget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setNombreCuaderno(response.data.nombre); // Asignar el nombre del cuaderno al estado
      } catch (error) {
        console.error("Error fetching cuaderno:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCuaderno();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `${window.BACKEND_URL}editartipocuaderno/${id}`,
        { nombre: nombreCuaderno },
        {
          headers: {
            Authorization: ` ${sessionStorage.getItem("token")}`,
          },
        }
      );
      alert("Cuaderno actualizado correctamente");
      window.history.back();
    } catch (error) {
      console.error("Error updating cuaderno:", error);
      alert("Error al actualizar cuaderno");
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Editar Cuaderno</h5>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <form onSubmit={handleUpdate}>
              <div className="mb-3">
                <label htmlFor="nombreCuaderno" className="form-label">
                  Nombre del cuaderno
                </label>
                <input
                  type="text"
                  id="nombreCuaderno"
                  className="form-control"
                  value={nombreCuaderno}
                  onChange={(e) => setNombreCuaderno(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary me-2">
                Guardar
              </button>
              <Link to="/tipo-cuaderno" className="btn btn-secondary">
                Cancelar
              </Link>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EditCuaderno;
