import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

const AddTipoJuzgado = () => {
  const [nombre, setNameJuzgad] = useState("");
  const [abrevacion, setAbreJuzgad] = useState("");

  const handleCancel = () => {
    window.history.back();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${window.BACKEND_URL}tipo_juzgadopost`,
        {
          nombre: nombre,
          abrevacion: abrevacion,
        }
      );
      console.log(response.data);
      window.history.back();
    } catch (error) {
      console.error(error);
      // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar tipo de juzgado</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="nombre" className="form-label">
                Nombre
              </label>
              <br />
              <input
                type="text"
                id="nombre"
                name="nombre"
                className="form-control"
                value={nombre}
                onChange={(e) => setNameJuzgad(e.target.value)}
              />
              <label htmlFor="abrevacion" className="form-label">
                Abreviacion
              </label>
              <br />
              <input
                type="text"
                id="abrevacion"
                name="abrevacion"
                className="form-control"
                value={abrevacion}
                onChange={(e) => setAbreJuzgad(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary me-2">
              Guardar
            </button>
            <Link
              to="/tipo_juzgado"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTipoJuzgado;
