import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditarJuzgado = () => {
  const { id } = useParams();
  const [juzgado, setJuzgado] = useState([]); // Aquí se almacenará el nombre del juzgado
  const [loading, setLoading] = useState(true);
  const [tipojus, settipojuzg] = useState([]);

  useEffect(() => {
    const fetchJuzgado = async () => {
      console.log(id);
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}juzgadoidlistget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.log("response: ", response.data);
        setJuzgado(response.data); // Asignar el nombre del juzgado al estado
      } catch (error) {
        console.error("Error fetching juzgado:", error);
      } finally {
        setLoading(false);
      }
    };
    const gettipojuzgo = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}tipo_juzgado`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });
        const data = await response.json();

        if (Array.isArray(data)) {
          settipojuzg(data);
        } else {
          console.error("La respuesta de los judiciales no es un array:");
        }
      } catch (error) {
        console.error("Error al obtener los judiciales:", error);
      }
    };
    gettipojuzgo();

    fetchJuzgado();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    // Seleccionar todos los formularios en el documento
    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    try {
      await axios.put(`${window.BACKEND_URL}juzgadoput/${id}`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      alert("Juzgado actualizado correctamente");
      window.history.back();
    } catch (error) {
      console.error("Error updating juzgado:", error);
      alert("Error al actualizar juzgado");
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Edita el juzgado</h5>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <form onSubmit={handleUpdate}>
              <div>
                <div className="mb-3">
                  <label htmlFor="materia" className="form-label">
                    Materia:
                  </label>
                  <input
                    type="text"
                    value={juzgado[0]?.materia}
                    className="form-control"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label>Distrito Judicial:</label>
                  <input
                    type="text"
                    value={juzgado[0]?.datadistrito[0]?.nombre}
                    className="form-control"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label>Sede:</label>
                  <input
                    type="text"
                    value={juzgado[0]?.datasede[0]?.sede}
                    className="form-control"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="descripcion" className="form-label">
                    Tipo de juzgado:
                  </label>
                  <select
                    type="text"
                    name="tijuzgado_id"
                    className="form-select"
                  >
                    {tipojus.map((tipoju) => (
                      <option
                        key={tipoju._id}
                        value={tipoju._id}
                        selected={
                          tipoju._id === (juzgado[0]?.datajuzgado[0]?._id || "")
                        }
                      >
                        {tipoju.nombre}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="nombrej">Nombre :</label>
                  <input
                    type="text"
                    name="nombrejuzgado"
                    className="form-control"
                    defaultValue={juzgado[0]?.nombrejuzgado}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="njuzgado">Numero de juzgado:</label>
                  <input
                    type="text"
                    
                    name="njuzgado"
                    defaultValue={juzgado[0]?.njuzgado}
                    className="form-control"
                  />
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleCancel}
                  >
                    Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                  editar juzgado
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EditarJuzgado;
