import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditPassword = () => {
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    window.history.back();
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const updatedPassword = {
        password: password,
      };

      const response = await axios.put(
        `${window.BACKEND_URL}users/${id}/password`,
        updatedPassword,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        window.history.back();
      } else {
        console.error(
          "Error al actualizar la contraseña:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error al procesar la solicitud:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Editar contraseña</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Nueva Contraseña:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleCancel}
                  >
                    Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? "Cargando..." : "Guardar"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPassword;
