import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Editjudi = () => {
  const { id } = useParams();
  const [titulo, setTitulo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTitulo = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}distrito_judicialget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setTitulo(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching distrito judicial:", error);
        setLoading(false);
      }
    };

    fetchTitulo();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTitulo({ ...titulo, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await axios.put(
        `${window.BACKEND_URL}distrito_judicialput/${id}`,
        titulo,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      // Puedes redirigir a otra página después de la edición
      window.history.back();
      /*    window.location.href = "/admin/titulo-decursos"; */
    } catch (error) {
      console.error("Error updating titulo:", error);
    }
  };

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Editar titulo</h5>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label htmlFor="nombre" className="form-label">
            Nombre del Titulo:
          </label>
          <input
            type="text"
            id="nombre"
            className="form-control"
            name="nombre"
            value={titulo.nombre}
            onChange={handleInputChange}
          />
        </div>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Guardar
        </button>
      </div>
    </div>
  );
};

export default Editjudi;
