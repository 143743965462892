import { useState } from 'react';
import ReactDOM from 'react-dom/client';

const Blogs = () => {

    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(inputs);
    }

    return (<>
        <h1 className="h3 mb-3">Blank Page</h1>

        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Empty card</h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="name@example.com"
                                    value={inputs.email || ""}
                                    onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1"
                                    className="form-label"
                                >Example textarea</label>
                                <textarea className="form-control"
                                    id="message"
                                    name="message"
                                    rows="3"
                                    value={inputs.message || ""}
                                    onChange={handleChange}></textarea>
                            </div>
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary" > Grabar </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default Blogs;
