import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

const AddViaProcedi = () => {
  const [materia, setMateria] = useState("");
  const [viaprocedi, setViaprocedi] = useState("");

  const handleCancel = () => {
    window.history.back();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${window.BACKEND_URL}via_prodipost`, {
        materia: materia,
        viaprocedi: viaprocedi,
      });
      console.log(response.data);
      window.history.back();
    } catch (error) {
      console.error(error);
      // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  const handleSelectChange = (e) => {
    setMateria(e.target.value);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="float-end">
            <Link to="/agregar-via_prodi" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i> Agregar tipo de juzgado
            </Link>
          </span>
          <h3 className="card-title mb-0">Lista de Docentes</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="form-group mb-3">
                <label htmlFor="materia" className="form-label">
                  Materias
                </label>
                <br />
                <select
                  id="select"
                  className="form-control"
                  name="materia"
                  value={materia}
                  onChange={handleSelectChange}
                  required
                >
                  <option value="">Seleciones una materia</option>
                  <option value="CIVIL">CIVIL</option>
                  <option value="CONSTITUCIONAL">CONSTITUCIONAL</option>
                  <option value="CONSTENCION ADMINISTRATIVA">
                    CONSTENCION ADMINISTRATIVA
                  </option>
                  <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                  <option value="LABORAL">LABORAL</option>
                </select>
              </div>
              <label htmlFor="viaprocedi" className="form-label">
                Nombre
              </label>
              <br />
              <input
                type="text"
                id="viaprocedi"
                name="viaprocedi"
                className="form-control"
                value={viaprocedi}
                onChange={(e) => setViaprocedi(e.target.value.toUpperCase())}
              />
            </div>
            <button type="submit" className="btn btn-primary me-2">
              Guardar
            </button>
            <Link
              to="/via_prodi"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddViaProcedi;
