import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const Edituser = () => {
  const [rol, setRol] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}users/${id}`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });
        const userData = response.data;

        setRol(userData.role);
        setName(userData.name);
        setEmail(userData.email);
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleRolChange = (event) => {
    setRol(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedUserData = {
        email: email,
        name: name,
        role: rol,
      };

      const response = await axios.put(
        `${window.BACKEND_URL}users/${id}`,
        updatedUserData
      );

      if (response.status === 200) window.history.back();
      {
        console.log("Usuario actualizado exitosamente");
      }
    } catch (error) {
      console.error("Error al actualizar el usuario:", error.message);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Empty card</h5>
            </div>
            <div className="card-body">
              <form className="p-3" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="role" className="form-label">
                    Rol
                  </label>
                  <select
                    className="form-select"
                    id="role"
                    name="role"
                    value={rol}
                    onChange={handleRolChange}
                    required
                  >
                    <option disabled value="">
                      Seleccione un rol
                    </option>
                    <option value="Admin">Admin</option>
                    <option value="Docente">Docente</option>
                    <option value="Alumno">Alumno</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <Link className="btn btn-primary" onClick={handleCancel}>
                    Cancelar
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Guardar usuario
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edituser;
