import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AgregarUrp = () => {
    const [monto, setMonto] = useState("");
    const [year, setYear] = useState("");
    const [loading, setLoading] = useState(false);
    const [sedeGuardada, setSedeGuardada] = useState(false);

    const handleChangeMonto = (e) => {
        setMonto(e.target.value);
    };

    const handleGrabarSede = async () => {
        setLoading(true);
        try {
            await axios.post(
                `${window.BACKEND_URL}agregarurp`,
                { monto: monto, year: year },
                {
                    headers: {
                        Authorization: `${sessionStorage.getItem("token")}`,
                    },
                }
            );
            setSedeGuardada(true);
            // Redirigir a la página de sede-cursos después de guardar exitosamente la sede
            window.location.href = "urp";
        } catch (error) {
            console.error("Error al guardar la sede de la URP:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelar = () => {
        // Aquí puedes implementar la lógica para cancelar la operación de agregar sede de la URP
        // Por ahora, simplemente limpiaremos el campo del nombre de la sede
        setMonto("");
        setYear("");
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">AGREGAR SEDE DE LA URP</h5>
                    </div>
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="year" className="form-label">Año:</label>
                            <DatePicker
                                selected={year}
                                onChange={(date) => setYear(date)}
                                dateFormat="yyyy"
                                showYearPicker
                                className="form-control"
                                placeholderText="Seleccione un año"
                                disabled={loading}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="monto" className="form-label">Monto:</label>
                            <input 
                                type="text" 
                                id="monto" 
                                className="form-control" 
                                value={monto} 
                                onChange={handleChangeMonto} 
                                disabled={loading} 
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button 
                                className="btn btn-primary me-2" 
                                onClick={handleGrabarSede} 
                                disabled={loading || monto.trim() === "" || !year} 
                            >
                                Grabar
                            </button>
                            <Link 
                                to="/urp" 
                                className="btn btn-secondary" 
                                disabled={loading}
                            >
                                Cancelar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgregarUrp;
