import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Editcurso = () => {
  const { id } = useParams();
  const [curso, setCurso] = useState(null);
  const [titulos, setTitulos] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [sedecursos, setSedecursos] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`${window.BACKEND_URL}cursosget/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCurso(response.data);
        setSelectedYear(new Date(response.data.anio));
      })
      .catch((error) => {
        console.error("Error fetching curso:", error);
      });
    axios
      .get(`${window.BACKEND_URL}users?role=Docente`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
    axios
      .get(`${window.BACKEND_URL}titulo`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setTitulos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching titulos:", error);
      });

    axios
      .get(`${window.BACKEND_URL}turno`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setTurnos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching turno:", error);
      });

    axios
      .get(`${window.BACKEND_URL}periodo`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPeriodos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching periodo:", error);
      });

    axios
      .get(`${window.BACKEND_URL}sedelist`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setSedecursos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sedecurso:", error);
      });
  }, [id]);

  const handleYearChange = (date) => {
    setSelectedYear(date);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      titulo_id: event.target.titulo_id.value,
      aula: event.target.aula.value,
      seccion: event.target.seccion.value,
      turno_id: event.target.turno_id.value,
      anio: selectedYear ? selectedYear.getFullYear() : null,
      periodo: event.target.periodo.value,
      usuario_id: event.target.usuario_id.value,
      sedecurso_id: event.target.sedecurso_id.value,
      periodoa_id: event.target.periodoa_id.value,
    };

    axios
      .put(`${window.BACKEND_URL}cursosput/${id}`, formData)
      .then((response) => {
        window.history.back();

      })
      .catch((error) => {
        console.error("Error al actualizar curso:", error);
      });
  };

  if (!curso) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Editar Curso</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="tituloSelect">
                Seleccione un título del curso:
              </label>
              <select
                id="tituloSelect"
                className="form-control"
                name="titulo_id"
                value={curso.titulo_id} // Usamos 'value' en lugar de 'defaultValue'
                onChange={(e) =>
                  setCurso({ ...curso, titulo_id: e.target.value })
                } // Actualizamos el estado 'curso' cuando se selecciona un título
                required
              >
                <option value="">Seleccione un título</option>
                {titulos.map((titulo) => (
                  <option key={titulo._id} value={titulo._id}>
                    {titulo.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="aula">Aula:</label>
              <input
                type="text"
                id="aula"
                className="form-control"
                name="aula"
                defaultValue={curso.aula}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="seccion">Sección:</label>
              <input
                type="text"
                id="seccion"
                className="form-control"
                name="seccion"
                defaultValue={curso.seccion}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="turnoSelect">Seleccione un turno:</label>
              <select
                id="turnoSelect"
                className="form-control"
                name="turno_id"
                value={curso.turno_id} // Usamos 'value' en lugar de 'defaultValue'
                onChange={(e) =>
                  setCurso({ ...curso, turno_id: e.target.value })
                } // Actualizamos el estado 'curso' cuando se selecciona un turno
              >
                <option value="">Seleccione un turno</option>
                {turnos.map((turno) => (
                  <option key={turno._id} value={turno._id}>
                    {turno.turno}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="anio">Año:</label>
              <br />
              <DatePicker
                id="anio"
                name="anio"
                className="form-control"
                selected={selectedYear} // Usamos 'selected' en lugar de 'defaultValue'
                onChange={handleYearChange}
                dateFormat="yyyy"
                showYearPicker
                placeholderText="Seleccione un año"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="periodoSelect">Seleccione un período:</label>
              <select
                id="periodoSelect"
                className="form-control"
                name="periodo"
                defaultValue={curso.periodo}
              >
                <option value="">Seleccione un período</option>
                <option value="SEMESTRE IMPAR">SEMESTRE IMPAR</option>
                <option value="SEMESTRE PAR">SEMESTRE PAR</option>
                <option value="VERANO">VERANO</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="userSelect">Seleccione un docente:</label>
              <select
                id="userSelect"
                className="form-control"
                name="usuario_id"
                value={curso.usuario_id} // Usamos 'value' en lugar de 'defaultValue'
                onChange={(e) =>
                  setCurso({ ...curso, usuario_id: e.target.value })
                } // Actualizamos el estado 'curso' cuando se selecciona un docente
                required
              >
                <option value="">Seleccione un docente</option>
                {users
                  .filter((user) => user.role === "Docente")
                  .map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="sedeSelect">Seleccione una sede:</label>
              <select
                id="sedeSelect"
                className="form-control"
                name="sedecurso_id"
                value={curso.sedecurso_id} // Usamos 'value' en lugar de 'defaultValue'
                onChange={(e) =>
                  setCurso({ ...curso, sedecurso_id: e.target.value })
                }
              >
                <option value="" disabled>Seleccione una sede</option>
                {sedecursos.map((sedecurso) => (
                  <option key={sedecurso._id} value={sedecurso._id}>
                    {sedecurso.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="periodoaSelect">
                Seleccione un período académico:
              </label>
              <select
                id="periodoaSelect"
                className="form-control"
                name="periodoa_id"
                value={curso.periodoa_id} // Usamos 'value' en lugar de 'defaultValue'
                onChange={(e) =>
                  setCurso({ ...curso, periodoa_id: e.target.value })
                } // Actualizamos el estado 'curso' cuando se selecciona un período académico
              >
                <option value="">Seleccione un período académico</option>
                {periodos
                  .filter((periodo) => periodo.estado === true)
                  .map((periodo) => (
                    <option key={periodo._id} value={periodo._id}>
                      {new Date(periodo.fecha_fin).toLocaleDateString()} -{" "}
                      {new Date(periodo.fecha_in).toLocaleDateString()}
                    </option>
                  ))}
              </select>
            </div>
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-primary">
                  Guardar Cambios
                </button>
              </div>
              <div className="col">
                <button type="submit" className="btn btn-secondary" onClick={() => {
                  window.history.back();
                }}>
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Editcurso;
