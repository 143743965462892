import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const AgregarPretension = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);

    try {
      // Lógica para guardar la pretensión
      await axios.post(
        `${window.BACKEND_URL}agregarpretension`,
        formData, // Incluye el tipo de pretensión en la solicitud
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Redirigir a la página de pretensiones después de guardar exitosamente la pretensión
      window.location.href = "pretensiones";
    } catch (error) {
      console.error("Error al guardar la pretensión:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">AGREGAR PRETENSIÓN</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="tipoPretension" className="form-label">
                  Materia:
                </label>
                <select name="tipo" className="form-select">
                  <option value="">Seleccionar tipo</option>
                  <option value="CIVIL">CIVIL</option>
                  <option value="CONSTITUCIONAL">CONSTITUCIONAL</option>
                  <option value="CONTENCIOSA ADMINISTRATIVA">
                    CONTENCIOSA ADMINISTRATIVA
                  </option>
                  <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                  <option value="LABORAL">LABORAL</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="nombrePretension" className="form-label">
                  Nombre de la pretensión:
                </label>
                <input
                  type="text"
                  id="nombrePretension"
                  className="form-control"
                  name="nombre"
                />
              </div>

              <div className="d-flex justify-content-end">
                <button className="btn btn-primary me-2" type="submit">
                  Grabar
                </button>
                <Link to="/pretensiones" className="btn btn-secondary">
                  Cancelar
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgregarPretension;
