import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Users from "./usuarios/user";
import Docente from "./usuarios/docente";
import Alumnos from "./usuarios/alumno";

import EditPassword from "./usuarios/editpasss";
import Edituser from "./usuarios/edituser";
import AddUsers from "./usuarios/adduser";
import Titulos from "./Titulos/titulocursos";
import Edittitle from "./Titulos/edittitle";
import Addtitle from "./Titulos/addtitle";

import Turno from "./Turnos/turno";
import Addturno from "./Turnos/addtruno";
import Editurno from "./Turnos/editruno";

import Periodo from "./PeriodoA/periodo";
import Addperiodo from "./PeriodoA/Addperiodo";
import Editperiodo from "./PeriodoA/Editperiodo";

import Judi from "./Judicial/judi";
import Addjudi from "./Judicial/Addjudi";
import Editjudi from "./Judicial/Editjudi";

import TipoJuzgado from "./TipoJuzgado/tipojuzgado";
import EditTipoJuzgado from "./TipoJuzgado/EditTipoJuzgado";
import AddTipoJuzgado from "./TipoJuzgado/AddTipoJuzgado";

import SalaSuprema from "./SalaSuprema/SalaSuprema";
import AddSalaSuprema from "./SalaSuprema/AddSalaSuprema";
import EditSalaSuprema from "./SalaSuprema/EditSalaSuprema";

import ViaProcedi from "./ViaProcedi/ViaProcedi";
import EditViaProcedi from "./ViaProcedi/EditViaProcedi";
import AddViaProcedi from "./ViaProcedi/AddViaProcedi";

import EntidadAdministrativa from "./EntidadesAdministrativas/entiadmi";
import EditEntiAdmi from "./EntidadesAdministrativas/EditEntiAdmi";
import AddEntiAdmi from "./EntidadesAdministrativas/AddEntiAdmi";

import AddEtapasMateria from "./EtapasMateria/AddEtapasMateria";
import EditEtapaMateria from "./EtapasMateria/EditEtapaMateria";
import EtapasMateria from "./EtapasMateria/EtapasMateria";

import SedesJudicial from "./SedesJudicial/SedesJudicial";
import AddSedesJudicial from "./SedesJudicial/AddSedesJudicial";
import EditSedeJudicial from "./SedesJudicial/EditSedeJudicial";

import SedeCursos from "./AdministracionAcademica/sede_cursos";
import AgregarSede from "./AdministracionAcademica/agregar_sede";
import EditarSede from "./AdministracionAcademica/editar_sede";

import TipoCuaderno from "./TipoCuaderno/tipo_cuaderno";
import AgregarCuaderno from "./TipoCuaderno/agregar_cuaderno";
import EditarCuaderno from "./TipoCuaderno/editar_cuaderno";

import Pretensiones from "./Pretensiones/pretensiones";
import AgregarPretension from "./Pretensiones/agregar_pretensiones";
import EditarPretension from "./Pretensiones/editar_pretensiones";

import Juzgados from "./Juzgados/juzgados";
import AgregarJuzgados from "./Juzgados/agregar_juzgados";
import EditarJuzgado from "./Juzgados/editar_juzgados";

import PageUrp from "./URP/urp";
import AgregarUrp from "./URP/agregar_urp";
import EditarUrp from "./URP/editar_urp";

import Salas from "./Salas/Salas";
import EditSalas from "./Salas/EditSalas";
import AddSalas from "./Salas/AddSalas";

import Cursos from "./cursos/curso";
import Editcurso from "./cursos/editcursso";
import Addcurso from "./cursos/addcurso";

import AgregarAlumno from "./agregaralumno/agregar_alumno";

import Estadistica from "./Estadistica/dash";

import Calendario from "./Calendariodeaudiencias/Calendarioaudiencias";

import Materia from "./Materia/Materia";
import EditMateria from "./Materia/EditMateria";
import AddMateria from "./Materia/AddMateria";

const ProtectedRoute = ({}) => {
  if (sessionStorage.getItem("userName") == null) {
    return <Navigate to={"/login"} replace />;
  }
  return <Layout />;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
          //lista de usuarios
          <Route path="lista-de-usuarios" element={<Users />} />
          <Route path="lista-de-Docente" element={<Docente />} />
          <Route path="lista-de-Alumnos" element={<Alumnos />} />
          <Route path="agregar-usuario" element={<AddUsers />} />
          <Route path="Editar-contraseña/:id" element={<EditPassword />} />
          <Route path="Editar-usuario/:id" element={<Edituser />} />
          //titulo de crusos
          <Route path="admin/titulo-decursos" element={<Titulos />} />
          <Route path="admin/editar-titulo/:id" element={<Edittitle />} />
          <Route path="admin/agregar-titulo" element={<Addtitle />} />
          //Lista de turno
          <Route path="turnos" element={<Turno />} />
          <Route path="editar-turno/:id" element={<Editurno />} />
          <Route path="agregar-turno" element={<Addturno />} />
          //lista periodo academico
          <Route path="periodo-academico" element={<Periodo />} />
          <Route path="editar-periodo/:id" element={<Editperiodo />} />
          <Route path="agregar-periodo" element={<Addperiodo />} />
          //LISTA ADMINISTRACION ACADEMICA
          <Route path="sede-curso" element={<SedeCursos />} />
          <Route path="agregar-sede" element={<AgregarSede />} />
          <Route path="Editar-sede/:id" element={<EditarSede />} />
          // Judicial
          <Route path="distrito-judicial" element={<Judi />} />
          <Route path="editar-judicial/:id" element={<Editjudi />} />
          <Route path="agregar-judicial" element={<Addjudi />} />
          // Materia
          <Route path="materia" element={<Materia />} />
          <Route path="editar_materia/:id" element={<EditMateria />} />
          <Route path="agregar_materia" element={<AddMateria />} />
          //LISTA ADMINISTRACION EXPEDIENTES
          <Route path="tipo-cuaderno" element={<TipoCuaderno />} />
          <Route path="agregar-cuaderno" element={<AgregarCuaderno />} />
          <Route path="Editar-cuaderno/:id" element={<EditarCuaderno />} />
          // Tipo de juzgado
          <Route path="tipo_juzgado" element={<TipoJuzgado />} />
          <Route path="editar-tipo_juzgado/:id" element={<EditTipoJuzgado />} />
          <Route path="agregar-tipo_juzgado" element={<AddTipoJuzgado />} />
          // Sala Suprema
          <Route path="sala_suprema" element={<SalaSuprema />} />
          <Route path="editar-sala_suprema/:id" element={<EditSalaSuprema />} />
          <Route path="agregar-sala_suprema" element={<AddSalaSuprema />} />
          // via Procedi
          <Route path="via_prodi" element={<ViaProcedi />} />
          <Route path="editar-via_prodi/:id" element={<EditViaProcedi />} />
          <Route path="agregar-via_prodi" element={<AddViaProcedi />} />
          // Entidad Administrativa
          <Route path="enti_admi" element={<EntidadAdministrativa />} />
          <Route path="editar-enti_admi/:id" element={<EditEntiAdmi />} />
          <Route path="agregar-enti_admi" element={<AddEntiAdmi />} />
          // Salas
          <Route path="salas" element={<Salas />} />
          <Route path="editar-salas/:id" element={<EditSalas />} />
          <Route path="agregar-salas" element={<AddSalas />} />
          // Sedes Judicial
          <Route path="sede_judicial" element={<SedesJudicial />} />
          <Route
            path="editar-sede_judicial/:id"
            element={<EditSedeJudicial />}
          />
          <Route path="agregar-sede_judicial" element={<AddSedesJudicial />} />
          // Etapas de materias
          <Route path="etapa_materia" element={<EtapasMateria />} />
          <Route
            path="editar-etapa_materia/:id"
            element={<EditEtapaMateria />}
          />
          <Route path="agregar-etapa_materia" element={<AddEtapasMateria />} />
          // Pretensiones
          <Route path="pretensiones" element={<Pretensiones />} />
          <Route path="agregar-pretension" element={<AgregarPretension />} />
          <Route path="Editar-pretension/:id" element={<EditarPretension />} />
          // Juzgados
          <Route path="juzgados" element={<Juzgados />} />
          <Route path="agregar-juzgados" element={<AgregarJuzgados />} />
          <Route path="Editar-juzgado/:id" element={<EditarJuzgado />} />
          // urp
          <Route path="urp" element={<PageUrp />} />
          <Route path="agregar-urp" element={<AgregarUrp />} />
          <Route path="editar-urp/:id" element={<EditarUrp />} />
          //listado de cursos
          <Route path="cursos" element={<Cursos />} />
          <Route path="editar-curso/:id" element={<Editcurso />} />
          <Route path="agregar-curso" element={<Addcurso />} />
          //agregar alumno
          <Route path="agregar-alumno/:id" element={<AgregarAlumno />} />
          //Estadistica
          <Route path="estadistica" element={<Estadistica />} />
          //Calendario
          <Route path="Calendarioaudiencias" element={<Calendario />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
