import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs5";
import axios from "axios";

const Periodo = () => {
    const [periodos, setPeriodos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const tableRef = useRef(null);

    const fetchPeriodos = async () => {
        try {
            const response = await axios.get(`${window.BACKEND_URL}periodo`, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            setPeriodos(response.data);

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const initializeDataTable = async () => {
            if (!tableRef.current) {
                return;
            }

            // Limpiar DataTable si ya está inicializado
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }

            // Inicializar DataTable
            $(tableRef.current).DataTable({
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
                },
                columnDefs: [
                    {
                        targets: [0, 1],
                        render: function (data, type, row) {
                            if (type === "display" || type === "filter") {
                                return new Date(data).toLocaleDateString();
                            }
                            return data;
                        },
                    },
                ],
            });
        };

        fetchPeriodos();
        initializeDataTable();

        // Limpieza al desmontar el componente
        return () => {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }
        };
    }, []);
    const handleDelete = async (id) => {
        try {
            await axios.delete(`${window.BACKEND_URL}periododelete/${id}`, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            // Actualizar la lista de periodos académicos después de la eliminación
            setPeriodos(periodos.filter((periodo) => periodo._id !== id));
        } catch (error) {
            console.error(error);
            // Manejar el error, como mostrar un mensaje de error al usuario
        }
    };


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <span className="float-end">
                        <Link to="/agregar-periodo" className="btn btn-primary">
                            <i className="fa-solid fa-plus"></i> Agregar un periodo academico
                        </Link>
                    </span>
                    <h3 className="card-title mb-0">Periodo académico</h3>
                </div>
                <div className="card-body">
                    {isLoading ? (
                        <p>Cargando...</p>
                    ) : (
                        <table className="table table-bordered" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Fecha inicio</th>
                                    <th>Fecha fin</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {periodos.map((periodo) => (
                                    <tr key={periodo._id}>
                                        <td>{new Date(periodo.fecha_in).toLocaleDateString()}</td>
                                        <td>{new Date(periodo.fecha_fin).toLocaleDateString()}</td>
                                        <td>{periodo.estado ? "Activo" : "Inactivo"}</td>
                                        <td>
                                            <Link
                                                to={`/editar-periodo/${periodo._id}`}
                                                className="btn btn-primary me-2"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i> Editar Periodo
                                            </Link>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleDelete(periodo._id)}
                                            >
                                                Eliminar
                                            </button>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
};

export default Periodo;
