import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DataTable from "datatables.net-bs5";

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);

  const tableRef = useRef(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response2 = await axios.get(
          process.env.REACT_APP_API_URL + "post/list",
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        setPosts(response2.data);

        setIsLoaded(false);
        new DataTable(tableRef.current);
      } catch (error) {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      }
    };

    if (isLoaded) {
      fetchPosts();
      // Reseteamos el estado para evitar un bucle de carga continuo
    }
  }, [isLoaded]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <img
                src="img/logo/logostemisnormal.png"
                alt="Logo de Stemis"
                className="mr-3"
                style={{ maxWidth: "100px" }}
              />

              <div className="d-flex align-items-center">
                <h1 className="h3 mb-0">
                  ¡Bienvenido al Administrador de Stemis!
                </h1>
              </div>
            </div>
            <div className="card-body">
              <p>
                Estás en el centro de control de Stemis, donde puedes dar forma
                a la experiencia de los usuarios.
              </p>
              <p>
                Desde aquí, puedes modificar la página principal, gestionar
                colaboradores, crear y editar publicaciones, y mucho más.
              </p>
              <p>
                Explora las opciones del menú para empezar a personalizar la
                experiencia de los visitantes de Stemis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
