import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Alumnos = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const token = sessionStorage.getItem("token");

  const fetchData = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}users`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}users/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      fetchData(); // Vuelve a cargar la lista de usuarios después de eliminar uno
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);

  const SubirExcel = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.type;
      if (
        fileType !== "application/vnd.ms-excel" &&
        fileType !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "¡Por favor, selecciona un archivo Excel!",
        });
        event.target.value = ""; // Limpia la selección del archivo
        setFileName(null);
        setFile(null);
      } else {
        setFileName(uploadedFile.name);
        setFile(uploadedFile);
      }
    }
  };

  const confirmarSubida = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await axios.post(
          `${window.BACKEND_URL}users/excel/post`,
          formData, // Incluye el tipo de pretensión en la solicitud
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        console.log("respuesat del excel", response.data.insertado);
        console.log("respuesat del excel", response.data.denegado);

        // Construir mensaje de éxito con la información de insertados y denegados
        let successMessage = "";

        if (response.data.insertado.length > 0) {
          successMessage += `Usuarios agregados correctamente:<br>`;
          response.data.insertado.forEach((user, index) => {
            successMessage += `${index + 1}. ${user}<br>`;
          });
          successMessage += `<br>`;
        }

        if (response.data.denegado.length > 0) {
          successMessage += `Usuarios denegados (ya existentes):<br>`;
          response.data.denegado.forEach((user, index) => {
            successMessage += `${index + 1}. ${user}<br>`;
          });
        }

        Swal.fire({
          icon: "success",
          title: "Guardado",
          html: successMessage,
        });
        setFileName(null);
        setFile(null);
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al guardar el archivo.",
        });
      }
    }
  };

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current || $.fn.DataTable.isDataTable(tableRef.current)) {
        return;
      }
      // Limpiar DataTable si ya está inicializado
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }
    // Limpieza al desmontar el componente
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [token, loading]);

  const adminUsers = users.filter((user) => user.role === "Alumno");

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <span className="float-end ">
                {fileName ? (
                  <button
                    className="btn btn-info me-2"
                    onClick={confirmarSubida}
                  >
                    <i class="fa-solid fa-check"></i> Confirmar Subida
                  </button>
                ) : (
                  <label
                    htmlFor="file"
                    className="btn btn-success me-2"
                    style={{ position: "relative" }}
                  >
                    Subir Excel Alumnos
                    <input
                      accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      type="file"
                      id="file"
                      name="file"
                      onChange={SubirExcel}
                      style={{
                        position: "absolute",
                        fontSize: "4px",
                        padding: 0,
                        margin: 0,
                        opacity: 0,
                        cursor: "pointer",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      title="Seleccionar archivo Excel"
                      required
                    />
                  </label>
                )}

                <Link to="/agregar-usuario" className="btn btn-primary">
                  <i className="fa-solid fa-plus"></i> Agregar Usuario
                </Link>
              </span>
              <h3 className="card-title mb-0">Lista de Alumnos</h3>
            </div>
            <div className="card-body">
              {loading ? (
                <p>Cargando usuarios...</p>
              ) : (
                <table className="table table-bordered" ref={tableRef}>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Rol</th> <th>Contraseña</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminUsers.map((user) => (
                      <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>

                        <td>{user.password ? "•••••••••••••••••" : ""}</td>
                        <td>
                          <div class="dropdown mb-1">
                            <a
                              class="btn btn-sm btn-primary"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="fas fa-ellipsis-v fa-lg me-2"></i>
                              Editar
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <Link
                                  to={`/Editar-usuario/${user._id}`}
                                  className="dropdown-item text-primary"
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                                  Editar
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/Editar-contraseña/${user._id}`}
                                  className="dropdown-item text-primary"
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                                  Editar contraseña
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(user._id)}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Alumnos;
