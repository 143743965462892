import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import "select2";

const AgregarAlumnos = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [alumnosDisponibles, setAlumnosDisponibles] = useState([]);
  const [alumnoSeleccionado, setAlumnoSeleccionado] = useState("");
  const [alumnosAgregados, setAlumnosAgregados] = useState([]);
  const [alumnosParaAgregar, setAlumnosParaAgregar] = useState([]);

  const fetchAlumnosDisponibles = useCallback(async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}users/alumno`);
      setAlumnosDisponibles(response.data);
    } catch (error) {
      console.error("Error fetching alumnos:", error);
    }
  }, []);

  const fetchAlumnosAgregados = useCallback(async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}cursoalumno/${id}/alumnos`
      );
      setAlumnosAgregados(response.data);
    } catch (error) {
      console.error("Error fetching alumnos agregados:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchAlumnosDisponibles();
    fetchAlumnosAgregados();
  }, [fetchAlumnosDisponibles, fetchAlumnosAgregados]);

  useEffect(() => {
    $(".select2")
      .select2()
      .on("select2:select", (e) => {
        const selectedAlumnoId = e.params.data.id;
        const selectedAlumnoName = e.params.data.text;
        console.log("Alumno seleccionado", selectedAlumnoId);
        setAlumnoSeleccionado({
          id: selectedAlumnoId,
          name: selectedAlumnoName,
        });
      });
  }, [alumnosDisponibles]);

  const handleEliminarAlumno = async (alumnoId) => {
    try {
      setLoading(true);
      await axios.put(`${window.BACKEND_URL}cursoalum/${id}/${alumnoId}`);
      setAlumnosAgregados((prevAlumnosAgregados) =>
        prevAlumnosAgregados.filter((alumno) => alumno._id !== alumnoId)
      );
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert(
          "Este alumno está asociado a un grupo del curso. Si borras al alumno, se borrarán todos los datos asociados a él en el curso."
        );
      } else {
        console.error("Error al eliminar el alumno del curso:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAgregarAlumno = () => {
    if (alumnoSeleccionado) {
      setAlumnosParaAgregar((prevAlumnos) => [
        ...prevAlumnos,
        alumnoSeleccionado,
      ]);
      setAlumnosDisponibles((prevAlumnosDisponibles) =>
        prevAlumnosDisponibles.filter(
          (alumno) => alumno._id !== alumnoSeleccionado.id
        )
      );
      setAlumnoSeleccionado("");
      $(".select2").val(null).trigger("change");
    }
  };

  const handleEnviarAlumnos = async () => {
    try {
      setLoading(true);
      const alumnosIds = alumnosParaAgregar.map((alumno) => alumno.id);
      await axios.put(`${window.BACKEND_URL}cursoalum/${id}`, {
        alu_ids: alumnosIds,
      });
      fetchAlumnosDisponibles();
      fetchAlumnosAgregados();
      setAlumnosParaAgregar([]);
      navigate("/cursos");
    } catch (error) {
      console.error("Error al agregar los alumnos al curso:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar Alumnos</h3>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <select
              className="form-select select2"
              id="alu_id"
              name="alu_id"
              value={alumnoSeleccionado.id || ""}
              onChange={(e) =>
                setAlumnoSeleccionado({
                  id: e.target.value,
                  name: e.target.options[e.target.selectedIndex].text,
                })
              }
            >
              <option value="" disabled>
                Seleccionar Alumno
              </option>
              {alumnosDisponibles.map(
                (alumno) =>
                  !alumnosAgregados.find((a) => a._id === alumno._id) && (
                    <option key={alumno._id} value={alumno._id}>
                      {alumno.name}
                    </option>
                  )
              )}
            </select>
          </div>
          <button
            type="button"
            className="btn btn-secondary mb-3"
            onClick={handleAgregarAlumno}
            disabled={!alumnoSeleccionado}
          >
            Agregar Alumno a la Lista
          </button>

          {alumnosParaAgregar.length > 0 && (
            <>
              <h5>Alumnos para agregar:</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  {alumnosParaAgregar.map((alumno) => (
                    <tr key={alumno.id}>
                      <td>{alumno.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                type="button"
                className="btn btn-primary mb-3"
                onClick={handleEnviarAlumnos}
                disabled={loading}
              >
                Agregar Alumnos
              </button>
            </>
          )}

          <h5>Alumnos ya agregados al curso:</h5>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {alumnosAgregados.map((alumno) => (
                <tr key={alumno._id}>
                  <td>{alumno.name}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleEliminarAlumno(alumno._id)}
                      disabled={loading}
                    >
                      {loading ? "Cargando..." : "Eliminar"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-between mt-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate("/cursos")}
            >
              Regresar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgregarAlumnos;
