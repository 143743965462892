import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";

const Turno = () => {
    const [turnos, setTurnos] = useState([]);
    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);

    const fetchTurnos = async () => {
        try {
            const response = await axios.get(`${window.BACKEND_URL}turno`, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            setTurnos(response.data);
        } catch (error) {
            console.error("Error fetching turnos:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${window.BACKEND_URL}turnodelete/${id}`, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            // Actualizar la lista de turnos después de eliminar el turno
            setTurnos(turnos.filter((turno) => turno._id !== id));
        } catch (error) {
            console.error("Error deleting turno:", error);
        }
    };

    useEffect(() => {
        fetchTurnos();
    }, []);

    useEffect(() => {
        const initializeDataTable = () => {
            if (!tableRef.current) {
                return;
            }

            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }

            $(tableRef.current).DataTable({
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
                },
            });
        };

        if (!loading) {
            initializeDataTable();
        }

        return () => {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }
        };
    }, [loading]);

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <span className="float-end">
                        <Link to="/agregar-turno" className="btn btn-primary">
                            <i className="fa-solid fa-plus"></i> Agregar Usuario
                        </Link>
                    </span>
                    <h3 className="card-title mb-0">Turno</h3>
                </div>
                <div className="card-body">
                    {loading ? (
                        <p>Cargando...</p>
                    ) : (
                        <table className="table table-bordered" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Turnos</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {turnos.map((turno) => (
                                    <tr key={turno._id}>
                                        <td>{turno.turno}</td>
                                        <td>
                                            <Link to={`/editar-turno/${turno._id}`} className="btn btn-primary me-2">
                                                <i className="fa-solid fa-pen-to-square me-2"></i>
                                                Editar

                                            </Link>

                                            <button className="btn btn-danger" onClick={() => handleDelete(turno._id)}>
                                                Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
};

export default Turno;
