import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditSede = () => {
    const { id } = useParams();
    const [sedeData, setSedeData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSede = async () => {
            try {
                const response = await axios.get(`${window.BACKEND_URL}sedeget/${id}`, {
                    headers: {
                        Authorization: `${sessionStorage.getItem("token")}`,
                    },
                });
                setSedeData(response.data); // Asignar los datos de la sede al estado
            } catch (error) {
                console.error("Error fetching sede:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSede();
    }, [id]);

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            await axios.put(`${window.BACKEND_URL}editarsede/${id}`, sedeData, {
                headers: {
                    Authorization: ` ${sessionStorage.getItem("token")}`,
                },
            });
            alert("Datos de la sede actualizados correctamente");
            window.history.back();
        } catch (error) {
            console.error("Error updating sede:", error);
            alert("Error al actualizar datos de la sede");
        }
    };

    const handleCancel = () => {
        window.history.back();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSedeData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Editar datos de la sede</h5>
                </div>
                <div className="card-body">
                    {loading ? (
                        <p>Cargando...</p>
                    ) : (
                        <form onSubmit={handleUpdate}>
                            <div className="mb-3">
                                <label htmlFor="nombre" className="form-label">Nombre</label>
                                <input
                                    type="text"
                                    id="nombre"
                                    name="nombre"
                                    className="form-control"
                                    value={sedeData.nombre || ""}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            {/* Agregar más campos según los datos de la sede */}
                            <button type="submit" className="btn btn-primary me-2">Guardar</button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancelar</button>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default EditSede;
