import { useState } from "react";
import { Link } from "react-router-dom";

const AddUsers = () => {
  const [rol, setRol] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dni, setDni] = useState("");
  const [photo, setPhoto] = useState(null);

  const handleRolChange = (e) => {
    setRol(e.target.value);
  };

  const handleFotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleCancel = () => {
    window.history.back();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("role", rol);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("dni", dni);
      if (photo) {
        formData.append("photo", photo);
      }

      // Enviar el formulario utilizando fetch
      const postResponse = await fetch(`${window.BACKEND_URL}users`, {
        method: "POST",
        body: formData,
      });

     
      if (!postResponse.ok) {
        // Si la respuesta no es exitosa, lanzar un error
        const errorData = await postResponse.json();
        throw new Error(errorData.mensaje || "Error al enviar el formulario");
      }

      // Si la respuesta es exitosa, navegar hacia atrás
      alert("Formulario enviado con éxito.");
      window.history.back();
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      alert(
        "Ocurrió un error al enviar el formulario. Por favor, intenta de nuevo."
      );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Agregar un nuevo usuario</h5>
            </div>
            <div className="card-body">
              <form className="p-3" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="role" className="form-label">
                    Rol
                  </label>
                  <select
                    className="form-select"
                    id="role"
                    name="role"
                    value={rol}
                    onChange={handleRolChange}
                    required
                  >
                    <option disabled value="">
                      Seleccione un rol
                    </option>
                    <option value="Admin">Admin</option>
                    <option value="Docente">Docente</option>
                    <option value="Alumno">Alumno</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="dni" className="form-label">
                    DNI (8 dígitos)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="dni"
                    name="dni"
                    value={dni}
                    onChange={(e) => setDni(e.target.value)}
                    required
                    minLength="8"
                    maxLength="8"
                  />
                </div>
                {rol === "Docente" && (
                  <div className="mb-3">
                    <label htmlFor="photo" className="form-label">
                      Foto del docente
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo"
                      name="photo"
                      onChange={handleFotoChange}
                      required
                    />
                  </div>
                )}
                <div className="d-flex justify-content-between">
                  <Link onClick={handleCancel} className="btn btn-primary">
                    Cancelar
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Guardar usuario
                  </button>
                </div>
              </form>
              {rol === "Alumno" && (
                <div className="mt-3">
                  <p>Los alumnos no tienen opción para subir una foto.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUsers;
