import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditEntiAdmi = () => {
  const { id } = useParams();
  const [titulo, setTitulo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTitulo = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}enti_admiget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        setTitulo(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching distrito judicial:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTitulo();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());
    console.log("Datos de todos los formularios:", formData);

    try {
      await axios.put(`${window.BACKEND_URL}enti_admiput/${id}`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      window.history.back();
    } catch (error) {
      console.error("Error updating nombre:", error);
      alert("Error al actualizar pretensión");
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Editar titulo</h5>
      </div>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <div className="card-body">
          <form onSubmit={handleUpdate}>
            <div className="mb-3">
              <label htmlFor="nombre" className="form-label">
                Nombre del Titulo:
              </label>
              <input
                type="text"
                id="nombre"
                className="form-control"
                name="nombre"
                defaultValue={titulo.nombre}
              />
            </div>
            <button className="btn btn-primary me-2">Guardar</button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditEntiAdmi;
