import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

const AddSalaSuprema = () => {
  const handleCancel = () => {
    window.history.back();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);

    try {
      const response = await axios.post(
        `${window.BACKEND_URL}sala_supremapost`,
        formData
      );
      console.log(response.data);
      window.history.back();
    } catch (error) {
      console.error(error);
      // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar sala suprema</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="materia" className="form-label">
                Materia
              </label>
              <br />

              <select
                className="form-select"
                id="materia"
                name="materia"
                required
              >
                <option disabled value="">
                  Seleccione un rol
                </option>
                <option value="Civil">Civil</option>
                <option value="Constitucional">Constitucional</option>
                <option value="Contecioso Administrativo">
                  Contecioso Administrativo
                </option>
                <option value="Familia Civil">Familia Civil</option>
                <option value="Laboral">Laboral</option>
              </select>
              <br />
              <label htmlFor="nombre" className="form-label">
                Nombre
              </label>
              <br />
              <input
                type="text"
                id="nombre"
                name="nombre"
                className="form-control"
              />
            </div>
            <br />
            <button type="submit" className="btn btn-primary me-2">
              Guardar
            </button>
            <Link
              to="/sala_suprema"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSalaSuprema;
