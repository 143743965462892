import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";

const SedeCursos = () => {
    const [sede, setSede] = useState([]);
    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);
    const token = sessionStorage.getItem("token");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BACKEND_URL}sedelist`, {
                    headers: {
                        Authorization: `${sessionStorage.getItem("token")}`,
                    },
                });
                setLoading(true);
                setSede(response.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const initializeDataTable = async () => {
            if (!tableRef.current || $.fn.DataTable.isDataTable(tableRef.current)) return;

            $(tableRef.current).DataTable({
                language: {
                    url: '//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json',
                },
            });
        };

        if (!loading) {
            initializeDataTable();
        }
    }, [loading]);

    const handleEliminarSede = async (id) => {
        //setLoading(true);
        try {
            console.log(id)
            await axios.delete(`${window.BACKEND_URL}eliminarsede/${id}`);
            // await axios.delete(`${window.BACKEND_URL}eliminarsede/${id}`, {
            //     headers: {
            //         Authorization: `${sessionStorage.getItem("token")}`,
            //     },
            // });

            // Filtrar la sede eliminada de la lista
            // const deletedPersona = sede.find((sede) => sede._id === id);
            // setDeleteMessage(`Persona "${deletedPersona.nombre}" eliminada`);
            // setShowModal(true);
            //setSede(prevState => prevState.filter(item => item.id !== id));
            setSede(sede.filter((sede) => sede._id !== id));
            navigate('/sede-curso');
            //setLoading(true);
        } catch (error) {
            console.error("Error al eliminar la sede:", error);
        } finally {
            //setLoading(false);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">SEDE CURSO</h5>
                        
                        <div className="d-flex justify-content-end mb-3">
                            <Link to="/agregar-sede" className="btn btn-primary">
                                Añadir Sede
                            </Link>
                        </div>
                    </div>
                    <div className="card-body">

                        {loading ? (
                            <p>Cargando administración...</p>
                        ) : (
                            <table className="table table-bordered" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sede.map((sedeItem) => (
                                        <tr key={sedeItem._id}>
                                            <td>{sedeItem.nombre}</td>
                                            <td>
                                                <Link
                                                    to={`/Editar-sede/${sedeItem._id}`}
                                                    className="btn btn-primary me-2"
                                                >
                                                    <i className="fa-solid fa-pen-to-square"></i> Editar Sede
                                                </Link>

                                                <button className="btn btn-danger" onClick={() => handleEliminarSede(sedeItem._id)}>
                                                    Eliminar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>



                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SedeCursos;
