import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const token = sessionStorage.getItem("token");

  const fetchData = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}users`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}users/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      fetchData(); // Vuelve a cargar la lista de usuarios después de eliminar uno
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current || $.fn.DataTable.isDataTable(tableRef.current)) {
        return;
      }

      // Limpiar DataTable si ya está inicializado
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [token, loading]);

  const adminUsers = users.filter((user) => user.role === "Admin");

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <span className="float-end">
                <Link to="/agregar-usuario" className="btn btn-primary">
                  <i className="fa-solid fa-plus"></i> Agregar Usuario
                </Link>
              </span>
              <h3 className="card-title mb-0">LIsta de administradores</h3>
            </div>
            <div className="card-body">
              {loading ? (
                <p>Cargando Administradores...</p>
              ) : (
                <table className="table table-bordered" ref={tableRef}>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Rol</th>{" "}
                      {/*Los roles solo van a ser Admin y Docente*/}
                      <th>Contraseña</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminUsers.map((user) => (
                      <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>

                        <td>{user.password ? "•••••••••••••••••" : ""}</td>
                        <td>
                          <div class="dropdown mb-1">
                            <a
                              class="btn btn-sm btn-primary"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="fas fa-ellipsis-v fa-lg me-2"></i>
                              Editar
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <Link
                                  to={`/Editar-usuario/${user._id}`}
                                  className="dropdown-item text-primary"
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                                  Editar
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/Editar-contraseña/${user._id}`}
                                  className="dropdown-item text-primary"
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                                  Editar contraseña
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(user._id)}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
