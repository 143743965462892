import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";

const EditUrp = () => {
  const [urpData, setUrpData] = useState({ year: new Date(), monto: "" });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchMonto = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}urpget/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      const fetchedData = response.data;

      // Convert fetched year to a valid Date object
      if (fetchedData.year) {
        fetchedData.year = new Date(fetchedData.year);
        if (isNaN(fetchedData.year.getTime())) {
          throw new Error("Invalid date format");
        }
      }

      setUrpData(fetchedData);
    } catch (error) {
      console.error("Error fetching URP:", error);
      alert("Error al cargar los datos de la URP");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMonto();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${window.BACKEND_URL}editarurp/${id}`, urpData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      alert("Monto de URP actualizado correctamente");
      navigate(-1); // Navigate to the previous page
    } catch (error) {
      console.error("Error updating URP:", error);
      alert("Error al actualizar monto de URP");
    }
  };

  const handleCancel = () => {
    navigate(-1); // Navigate to the previous page
  };
  
  const handleDateChange = (year) => {
    setUrpData((prevData) => ({
      ...prevData,
      year: year // Actualizar solo el año en el estado urpData
    }));
  };

  const handleMontoChange = (e) => {
    setUrpData((prevData) => ({
      ...prevData,
      monto: e.target.value,
    }));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Edita el monto de la URP</h5>
      </div>
      <div className="card-body">
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <form onSubmit={handleUpdate}>
            <div className="mb-3">
              <label htmlFor="year" className="form-label">
                Año
              </label>
              <DatePicker
                id="year"
                className="form-control"
                selected={urpData.year}
                onChange={handleDateChange}
                dateFormat="yyyy"
                showYearPicker
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="monto" className="form-label">
                Monto
              </label>
              <input
                type="text"
                id="monto"
                className="form-control"
                value={urpData.monto}
                onChange={handleMontoChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary me-2">
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditUrp;
