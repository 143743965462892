import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";

const SedesJudicial = () => {
  const [sedes, setSedeJudicial] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);

  const fetchSedeJudicial = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}sede_judicial`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      setSedeJudicial(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}sede_judicialdelete/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      // Actualizar la lista de turnos después de eliminar el turno
      setSedeJudicial(
        sedes.filter((sede_judicial) => sede_judicial._id !== id)
      );
    } catch (error) {
      console.error("Error deleting sede_judicial:", error);
    }
  };

  useEffect(() => {
    fetchSedeJudicial();
  }, []);

  useEffect(() => {
    const initializeDataTable = () => {
      if (!tableRef.current) {
        return;
      }

      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [loading]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="float-end">
            <Link to="/agregar-sede_judicial" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i> Agregar nuevo dato
            </Link>
          </span>
          <h3 className="card-title mb-0">Lista de sedes</h3>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <table className="table table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th>Distrito</th>
                  <th>Sedes</th>
                  <th>Numero</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sedes.map((data) => (
                  <tr key={data._id}>
                    <td>{data.datadistrito[0]?.nombre}</td>
                    <td>{data.sede}</td>
                    <td>{data.numero}</td>
                    <td>
                      <Link
                        to={`/editar-sede_judicial/${data._id}`}
                        className="btn btn-primary me-2"
                      >
                        <i className="fa-solid fa-pen-to-square me-2"></i>
                        Editar
                      </Link>

                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(data._id)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default SedesJudicial;
