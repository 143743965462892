import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const AddSedesJudicial = () => {
  const [distritos, setDistritos] = useState([]);

  const fetchDistritoJudicial = () => {
    axios
      .get(`${window.BACKEND_URL}distrito_judicial`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setDistritos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Distrito Judicial:", error);
      });
  };

  useEffect(() => {
    fetchDistritoJudicial();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);

    try {
      const response = await axios.post(
        `${window.BACKEND_URL}sede_judicialpost`,
        formData
      );
      console.log(response.data);
      window.history.back();
    } catch (error) {
      console.error(error);
      // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar sede</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="userSelect">Distrito</label>
              <br />
              <select
                className="form-control"
                id="distrito"
                name="distrito"
                required
              >
                <option value="">Seleccione un distrito</option>
                {distritos.map((distrito) => (
                  <option key={distrito._id} value={distrito._id}>
                    {distrito.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="sede" className="form-label">
                Sede
              </label>
              <br />
              <input
                type="text"
                id="sede"
                name="sede"
                className="form-control"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="sede" className="form-label">
                Numero
              </label>
              <br />
              <input
                type="text"
                id="numero"
                name="numero"
                className="form-control"
                inputMode="numeric"
                pattern="\d*"
                max="9999"
                min="0"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary me-2">
              Guardar
            </button>
            <Link to="/sede_judicial" className="btn btn-secondary">
              Cancelar
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSedesJudicial;
