import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";

const Docente = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}users`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}users/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      fetchData(); // Vuelve a cargar la lista de usuarios después de eliminar uno
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current || $.fn.DataTable.isDataTable(tableRef.current)) {
        return;
      }
      // Limpiar DataTable si ya está inicializado
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }

    // Limpieza al desmontar el componente
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [loading]);

  const docentes = users.filter((user) => user.role === "Docente");

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <span className="float-end">
                <Link to="/agregar-usuario" className="btn btn-primary">
                  <i className="fa-solid fa-plus"></i> Agregar Usuario
                </Link>
              </span>
              <h3 className="card-title mb-0">Lista de Docentes</h3>
            </div>
            <div className="card-body">
              {loading ? (
                <p>Cargando Docentes...</p>
              ) : (
                <table className="table table-bordered" ref={tableRef}>
                  <thead>
                    <tr>
                      <th>Foto</th>
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Rol</th>

                      <th>Contraseña</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docentes.map((docente) => (
                      <tr key={docente.id}>
                        <td>
                          {docente.photo && (
                            <img
                              src={`${window.BACKEND_URL}uploads/${docente.photo}`}
                              alt={`Foto de ${docente.name}`}
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </td>
                        <td>{docente.name}</td>
                        <td>{docente.email}</td>
                        <td>{docente.role}</td>
                        <td>{docente.password ? "•••••••••••••••••" : ""}</td>
                        <td>
                          <div className="dropdown mb-1">
                            <a
                              className="btn btn-sm btn-primary"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fas fa-ellipsis-v fa-lg me-2"></i>
                              Editar
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <Link
                                  to={`/Editar-usuario/${docente._id}`}
                                  className="dropdown-item text-primary"
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                                  Editar
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/Editar-contraseña/${docente._id}`}
                                  className="dropdown-item text-primary"
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                                  Editar contraseña
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(docente._id)}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Docente;
