import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

const AddEntiAdmi = () => {
  const [nombre, setEntiAdmi] = useState("");

  const handleCancel = () => {
    window.history.back();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${window.BACKEND_URL}enti_admipost`, {
        nombre: nombre,
      });
      console.log(response.data);
      window.history.back();
    } catch (error) {
      console.error(error);
      // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar entidad administrativa</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="titulocursos" className="form-label">
                Nombre
              </label>
              <br />
              <input
                type="text"
                id="titulocursos"
                className="form-control"
                value={nombre}
                onChange={(e) => setEntiAdmi(e.target.value)}
              />
            </div>
            <br />
            <button type="submit" className="btn btn-primary me-2">
              Guardar
            </button>
            <Link
              to="/enti_admi"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEntiAdmi;
