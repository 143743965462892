import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Addturno = () => {
    const [turno, setTurno] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${window.BACKEND_URL}turnopost`, { turno }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            });
            alert("Turno agregado correctamente");
            setTurno("");
        } catch (error) {
            console.error("Error al agregar turno:", error);
            alert("Error al agregar turno");
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Agregar turno</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="turno" className="form-label">Turno</label>
                            <input
                                type="text"
                                id="turno"
                                className="form-control"
                                value={turno}
                                onChange={(e) => setTurno(e.target.value)}
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary me-3"
                                onClick={() => {
                                    window.history.back();
                                }}
                            >
                                Agregar
                            </button>
                            <Link to="/turnos" className="btn btn-secondary">
                                Cancelar
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Addturno;
