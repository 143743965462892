import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";

const TipoCuaderno = () => {
  const [tiposCuaderno, setTiposCuaderno] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}tiposcuadernolist`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setLoading(true);
        setTiposCuaderno(response.data);
        console.log("SEDES=", tiposCuaderno);
      } catch (error) {
        console.error("Error fetching tipos de cuaderno:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current || $.fn.DataTable.isDataTable(tableRef.current))
        return;

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }
  }, [loading]);

  const handleEliminarTipoCuaderno = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}eliminartipocuaderno/${id}`);
      setTiposCuaderno(tiposCuaderno.filter((tipo) => tipo._id !== id));
      navigate("/tipo-cuaderno");
    } catch (error) {
      console.error("Error al eliminar el tipo de cuaderno:", error);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">TIPOS DE CUADERNO</h5>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-end mb-3">
              <Link to="/agregar-cuaderno" className="btn btn-primary">
                Añadir Tipo de Cuaderno
              </Link>
            </div>
            {loading ? (
              <p>Cargando tipos de cuaderno...</p>
            ) : (
              <table className="table table-bordered" ref={tableRef}>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {tiposCuaderno.map((tipo) => (
                    <tr key={tipo._id}>
                      <td>{tipo.nombre}</td>
                      <td>
                        <Link
                          to={`/Editar-cuaderno/${tipo._id}`}
                          className="btn btn-primary me-2"
                        >
                          <i className="fa-solid fa-pen-to-square "></i>
                          Editar
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleEliminarTipoCuaderno(tipo._id)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipoCuaderno;
