import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar, Doughnut } from "react-chartjs-2";

import Chart from "chart.js/auto";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Estadistica = () => {
  const [docentes, SetDocentes] = useState([]);
  const [alumnos, SetAlumnos] = useState([]);
  const [expedientes, setExpediente] = useState([]);
  const [grupos, setGrupos] = useState([]);

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ususarios = await axios.get(`${window.BACKEND_URL}users`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });
        const alumnos = ususarios.data.filter((user) => user.role === "Alumno");
        const docentes = ususarios.data.filter(
          (user) => user.role === "Docente"
        );
        const responseCursos = await axios.get(
          `${window.BACKEND_URL}cursosimu`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        const expedientes = await axios.get(`${window.BACKEND_URL}expediente`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });
        const grupos = await axios.get(`${window.BACKEND_URL}cursogrupo/full`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        });

        SetDocentes(docentes.length);
        SetAlumnos(alumnos.length);
        setExpediente(expedientes.data.length);
        setGrupos(grupos.data.length);

        const Civil = grupos.data.filter((valor) => valor.materia === "CIVIL");
        const FamiliaCivil = grupos.data.filter(
          (valor) => valor.materia === "FAMILIA CIVIL"
        );

        console.log("FamiliaCivil", FamiliaCivil);

        // \tRESOLUCIÓN CONTRATO
        // Función genérica para contar elementos según una pretensión específica
        const pretensionesCivil = [
          "ANULABILIDAD",
          "PRESCRIPCIÓN ADQUISITVA DE DOMINIO",
          "NULIDAD DE ACTO JURIDICO",
          "RETRACTO",
        ];

        const pretensionesFamiliaCivil = [
          "DIVORCIO",
          "TENENCIA",
          "PATRIA POTESTAD",
          "VARIACION DE TENENCIA",
        ];

        const contarPorPretension = (data, pretension) => {
          return data.filter((valor) =>
            valor.datapretension.some((preten) => preten.nombre === pretension)
          ).length;
        };

        const datosCivil = pretensionesCivil.map((pretension) =>
          contarPorPretension(Civil, pretension)
        );

        const datosFamiliaCivil = pretensionesFamiliaCivil.map((pretension) =>
          contarPorPretension(FamiliaCivil, pretension)
        );

        console.log("Datos familia civil", datosFamiliaCivil);

        setgraficaCivil({
          labels: pretensionesCivil,
          datasets: [
            {
              label: "Barra",
              data: datosCivil,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
              ],
              hoverOffset: 4,
            },
          ],
        });

        setgraficaFamiliaCivil({
          labels: pretensionesFamiliaCivil,
          datasets: [
            {
              label: "Barra",
              data: datosFamiliaCivil,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
              ],
              hoverOffset: 4,
            },
          ],
        });

        setbarraCivil({
          labels: pretensionesCivil,
          datasets: [
            {
              label: "Datos",
              data: datosCivil,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
              ],
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
              borderWidth: 1,
            },
          ],
        });

        setbarraFamiliaCivil({
          labels: pretensionesFamiliaCivil,
          datasets: [
            {
              label: "Datos",
              data: datosFamiliaCivil,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
              ],
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
              borderWidth: 1,
            },
          ],
        });

        // setUsuarios(responseUsuarios.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [graficacivil, setgraficaCivil] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
        ],
        hoverOffset: 4,
      },
    ],
  });
  const [graficafamiliacivil, setgraficaFamiliaCivil] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
        ],
        hoverOffset: 4,
      },
    ],
  });

  const [barradataCivil, setbarraCivil] = useState({
    labels: [],
    datasets: [
      {
        label: "Datos",
        data: [],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  });

  const [barraFamiliaCivil, setbarraFamiliaCivil] = useState({
    labels: [],
    datasets: [
      {
        label: "Datos",
        data: [],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  });

  return (
    <div class="container-fluid">
      <div className="container">
        <div class="row">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Estadística</h5>
              <div class="card">
                <label for="fechaInicio">Fecha de Inicio:</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                  id="fechaInicio"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div class="card">
                <label for="fechaFin">Fecha de Fin:</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control"
                  id="fechaFin"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body d-flex align-items-center">
                      <div className="pe-5">
                        <h5 className="card-title mb-0">Docentes</h5>
                        <span className="number display-5">{docentes}</span>
                      </div>
                      <i className="fa-solid fa-user fa-5x"></i>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card-body d-flex align-items-center">
                      <div className="pe-5">
                        <h5 className="card-title mb-0">Alumnos</h5>
                        <span className="number display-5">{alumnos}</span>
                      </div>
                      <i class="fa-solid fa-user-graduate fa-5x"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                i{" "}
                <div className="col">
                  <div className="card">
                    <div className="card-body d-flex align-items-center">
                      <div className="pe-5">
                        <h5 class="card- mb-0">Grupos</h5>
                        <span className="number display-5">{grupos}</span>
                      </div>
                      <i class="fa-solid fa-users fa-5x"></i>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card-body d-flex align-items-center">
                      <div className="pe-4">
                        <h5 class="card-title">Expedientes</h5>
                        <span className="number display-5">{expedientes}</span>
                      </div>
                      <i class="fa-solid fa-book fa-5x"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Pretensiones por materia civil</h5>
                <Bar
                  data={barradataCivil}
                  width={100}
                  height={50}
                  options={{ maintainAspectRatio: true }}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {" "}
                  Pretensiones por materia familia civil
                </h5>
                <Bar
                  data={barraFamiliaCivil}
                  width={100}
                  height={50}
                  options={{ maintainAspectRatio: true }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title"> Pretensiones por materia civil</h5>
                <Doughnut data={graficacivil} />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {" "}
                  Pretensiones por materia familia civil
                </h5>
                <Doughnut data={graficafamiliacivil} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Estadistica;
