import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

const AddSalas = () => {
  const navigate = useNavigate();
  const [sedejudi, setSedeJudicial] = useState([]);
  const [selectedJudicial, setSelectedJudicial] = useState(""); // Estado para el distrito judicial seleccionado

  const [disjudiciales, SetDistrito] = useState([]);

  const fetchSedeJudicial = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}sede_judicial`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      setSedeJudicial(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };

  const fetchdistrito = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}distrito_judicial`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      SetDistrito(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token");
      const formData = new FormData(e.target);

      const data = {
        nombre: formData.get("nombre"),
        materia: formData.get("materia"),
        distrito_id: selectedJudicial,
        sede_id: formData.get("sede_id"),
      };

      const response = await axios.post(
        `${window.BACKEND_URL}salaspost`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      navigate("/salas");
    } catch (error) {
      console.error("Error al agregar el juzgado", error.response.data);
      navigate("/salas");
    }
  };

  const Salas = () => {
    navigate("/salas");
  };

  useEffect(() => {
    fetchSedeJudicial();
    fetchdistrito();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar Sala</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="materia" className="form-label">
                Materia:
              </label>
              <select type="text" className="form-select" name="materia">
                <option value="">Seleccione una materia</option>
                <option value="CIVIL">Civil</option>
                <option value="CONSTITUCIONAL">CONSTITUCIONAL</option>
                <option value="CONTENCION ADMINISTRATIVA">
                  CONTENCION ADMINISTRATIVA
                </option>
                <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                <option value="LABORAL">LABRORAL</option>{" "}
              </select>
            </div>
            <div className="mb-3">
              <label>Distrito Judicial:</label>
              <select
                type="text"
                className="form-select"
                name="distrito_id"
                id="distrito_id"
                onChange={(e) => {
                  setSelectedJudicial(e.target.value);
                }}
              >
                <option value="">Selecciona un distrito judicial:</option>
                {disjudiciales.map((judicial) => (
                  <option key={judicial._id} value={judicial._id}>
                    {judicial.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label>Sede:</label>
              <select type="text" className="form-select" name="sede_id">
                <option value="">Selecciona una Sede:</option>
                {sedejudi
                  .filter((sede) => sede.distrito === selectedJudicial)
                  .map((sedej) => (
                    <option key={sedej._id} value={sedej._id}>
                      {sedej.sede}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="nombrej">Nombre :</label>
              <input
                type="text"
                id="nombre"
                className="form-control"
                name="nombre"
              />
            </div>

            <div className="d-flex justify-content-between mt-2">
              <button type="button" className="btn btn-primary" onClick={Salas}>
                Regresar
              </button>
              <button type="submit" className="btn btn-primary">
                Agregar Curso
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSalas;
