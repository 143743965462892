import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditTipoJuzgado = () => {
  const { id } = useParams();
  const [juzgado, setJuzgado] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJuzgado = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}tipo_juzgadoget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setJuzgado(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching distrito judicial:", error);
        setLoading(false);
      }
    };

    fetchJuzgado();
  }, [id]);

  const handleCancel = () => {
    window.history.back();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJuzgado({ ...juzgado, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`${window.BACKEND_URL}tipo_juzgadoput/${id}`, juzgado, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      // Puedes redirigir a otra página después de la edición
      window.history.back();
      /*    window.location.href = "/admin/titulo-decursos"; */
    } catch (error) {
      console.error("Error updating JUzgado:", error);
    }
  };

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Editar tipo de juzgado</h5>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label htmlFor="nombre" className="form-label">
            Nombre
          </label>
          <input
            type="text"
            id="nombre"
            className="form-control"
            name="nombre"
            value={juzgado.nombre}
            onChange={handleInputChange}
          />
          <br />
          <label htmlFor="distrito" className="form-label">
            Abreviacion
          </label>
          <input
            type="text"
            id="abrevacion"
            className="form-control"
            name="abrevacion"
            value={juzgado.abrevacion}
            onChange={handleInputChange}
          />
        </div>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Guardar
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleCancel}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default EditTipoJuzgado;
