import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs5";
import axios from "axios";

const Salas = () => {
  const [salas, setSalas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const fetchSalas = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}salas`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      setSalas(response.data);
      console.log("dadata", response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSalas();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current) {
        return;
      }

      // Limpiar DataTable si ya está inicializado
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      // Inicializar DataTable
      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!isLoading) {
      initializeDataTable();
    }

    // Limpieza al desmontar el componente
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [isLoading]);
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}salasdelete/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      // Actualizar la lista de salas académicos después de la eliminación
      setSalas(salas.filter((datas) => datas._id !== id));
      navigate("/salas");
    } catch (error) {
      console.error(error);
      // Manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="float-end">
            <Link to="/agregar-salas" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i> Agregar
            </Link>
          </span>
          <h3 className="card-title mb-0">Lista de salas</h3>
        </div>
        <div className="card-body">
          {isLoading ? (
            <p>Cargando...</p>
          ) : (
            <table className="table table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th>Materia</th>
                  <th>Distribucion Judicial</th>
                  <th>Sede</th>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {salas.map((datas) => (
                  <tr key={datas._id}>
                    <td>{datas.materia}</td>
                    <td>{datas.datadistrito[0].nombre}</td>
                    <td>{datas.datasede[0]?.sede}</td>
                    <td>{datas.nombre}</td>
                    <td>
                      <Link
                        to={`/editar-salas/${datas._id}`}
                        className="btn btn-primary me-2"
                      >
                        <i className="fa-solid fa-pen-to-square"></i> Editar
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(datas._id)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Salas;
