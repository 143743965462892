import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const EditarPretension = () => {
  const { id } = useParams();
  const [pretension, setPretension] = useState([]); // Aquí se almacenará el nombre de la pretensión
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPretension = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}pretensionget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.log("response: ", response.data);
        setPretension(response.data); // Asignar el nombre de la pretensión al estado
      } catch (error) {
        console.error("Error fetching pretension:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPretension();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);

    try {
      await axios.put(`${window.BACKEND_URL}editarpretension/${id}`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      alert("Pretensión actualizada correctamente");
      window.history.back();
    } catch (error) {
      console.error("Error updating pretension:", error);
      alert("Error al actualizar pretensión");
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Edita la pretensión</h5>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <form onSubmit={handleUpdate}>
              <div className="mb-3">
                <label htmlFor="pretension" className="form-label">
                  Pretensión
                </label>
                <input
                  type="text"
                  id="pretension"
                  className="form-control"
                  required
                  defaultValue={pretension.nombre}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="tipoPretension" className="form-label">
                  Tipo de pretensión:
                </label>
                <select id="tipoPretension" className="form-select">
                  <option value="">Seleccionar tipo</option>
                  <option value="CIVIL">CIVIL</option>
                  <option value="CONSTITUCIONAL">CONSTITUCIONAL</option>
                  <option value="CONTENCIOSA ADMINISTRATIVA">
                    CONTENCIOSA ADMINISTRATIVA
                  </option>
                  <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                  <option value="LABORAL">LABORAL</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary me-2">
                Guardar
              </button>
              <Link to="/pretensiones" className="btn btn-secondary">
                Cancelar
              </Link>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EditarPretension;
