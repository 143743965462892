import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";

const Cursos = () => {
  const [cursos, setCursos] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);

  const getCursos = () => {
    axios
      .get(`${window.BACKEND_URL}cursosimu`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCursos(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching cursos:", error);
        setLoading(false);
      });
  };
  const handleDelete = (id) => {
    axios
      .delete(`${window.BACKEND_URL}cursodelete/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        getCursos(); // Actualizar la lista de cursos después de eliminar uno
      })
      .catch((error) => {
        console.error("Error deleting curso:", error);
      });
  };

  useEffect(() => {
    getCursos();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
          },
        });
      }
    };
    initializeDataTable();
  }, [cursos]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="float-end">
            <Link to="/agregar-curso" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i> Agregar un Curso nuevo
            </Link>
          </span>
          <h3 className="card-title mb-0">Cursos</h3>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <table className="table table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th>Titulo</th>
                  <th>Aula</th>
                  <th>Seccion</th>
                  <th>Docente</th>
                  <th>Año</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cursos.map((curso) => (
                  <tr key={curso._id}>
                    <td>{curso.informacion_titulo[0].nombre}</td>
                    <td>
                      <img
                        className="card-img-top"
                        style={{
                          width: "30%",
                          height: "30%",
                          objectFit: "cover",
                        }}
                        src={`${window.BACKEND_URL}uploads/${curso.imagen}`}
                        alt={`Foto de ${curso.name}`}
                      />
                    </td>
                    <td>{curso.seccion}</td>
                    <td>{curso.informacion_usuario[0].name}</td>
                    <td>{curso.anio}</td>
                    <td>
                      <Link
                        to={`/editar-curso/${curso._id}`}
                        className="btn btn-primary me-2"
                      >
                        <i className="fa-solid fa-pen-to-square me-2"></i>
                        Editar
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(curso._id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                        Eliminar
                      </button>
                      <Link
                        to={`/agregar-alumno/${curso._id}`}
                        className="btn btn-success"
                      >
                        <i class="fa-solid fa-head-side-virus fa-lg2"></i>
                        Añadir Alumno
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Cursos;
