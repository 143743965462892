import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Editurno = () => {
    const { id } = useParams();
    const [turno, setTurno] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTurno = async () => {
            try {
                const response = await axios.get(`${window.BACKEND_URL}turnoget/${id}`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                });
                setTurno(response.data.turno);
            } catch (error) {
                console.error("Error fetching turno:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTurno();
    }, [id]);

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            await axios.put(`${window.BACKEND_URL}turnoput/${id}`, { turno: turno }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            });
            alert("Turno actualizado correctamente");
            window.history.back();
        } catch (error) {
            console.error("Error updating turno:", error);
            alert("Error al actualizar turno");
        }
    };
    const handleCancel = () => {
        window.history.back();
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Edita el turno</h5>
                </div>
                <div className="card-body">
                    {loading ? (
                        <p>Cargando...</p>
                    ) : (
                        <form onSubmit={handleUpdate}>
                            <div className="mb-3">
                                <label htmlFor="turno" className="form-label">Turno</label>
                                <input
                                    type="text"
                                    id="turno"
                                    className="form-control"
                                    value={turno}
                                    onChange={(e) => setTurno(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary me-2">Guardar</button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancelar</button>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default Editurno;
