import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Periodo = () => {
    const [fechaIn, setFechaIn] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${window.BACKEND_URL}periodopost`, {
                fecha_in: fechaIn,
                fecha_fin: fechaFin,
                estado: true,
            });
            console.log(response.data);
            window.history.back();
        } catch (error) {
            console.error(error);
            // Aquí puedes manejar el error, como mostrar un mensaje de error al usuario
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <span className="float-end">
                        <Link to="/agregar-periodo" className="btn btn-primary">
                            <i className="fa-solid fa-plus"></i> Agregar un periodo academico
                        </Link>
                    </span>
                    <h3 className="card-title mb-0">Lista de Docentes</h3>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>

                        <div className="mb-3">
                            <label htmlFor="fechaIn" className="form-label">Fecha Inicio</label>
                            <br />
                            <DatePicker
                                selected={fechaIn}
                                onChange={(date) => setFechaIn(date)}
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="fechaFin" className="form-label">Fecha Fin</label>
                            <br />
                            <DatePicker
                                selected={fechaFin}
                                onChange={(date) => setFechaFin(date)}
                                className="form-control"
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Periodo;
