import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditSalaSuprema = () => {
  const { id } = useParams();
  const [salasuprema, setSalaSuprema] = useState([]); // Aquí se almacenará el nombre de la pretensión
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNombre = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}sala_supremaget/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        setSalaSuprema(response.data); // Asignar el nombre de la pretensión al estado
      } catch (error) {
        console.error("Error fetching nombre:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNombre();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);

    try {
      await axios.put(`${window.BACKEND_URL}sala_supremaput/${id}`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      alert("Pretensión actualizada correctamente");
      window.history.back();
    } catch (error) {
      console.error("Error updating nombre:", error);
      alert("Error al actualizar pretensión");
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Editar sala suprema</h5>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <form onSubmit={handleUpdate}>
              <div className="mb-3">
                <label htmlFor="materia" className="form-label">
                  Materia:
                </label>
                <input
                  type="text"
                  id="materia"
                  className="form-control"
                  name="materia"
                  value={salasuprema.materia}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor="nombre" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  id="nombre"
                  defaultValue={salasuprema.nombre}
                  className="form-control"
                  name="nombre"
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary me-2">
                Guardar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EditSalaSuprema;
