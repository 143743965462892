import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";

const Juzgados = () => {
  const [juzgados, setJuzgados] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}juzgados`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setJuzgados(response.data);
      } catch (error) {
        console.error("Error fetching juzgados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // initializeDataTable();
  }, [token]);

  useEffect(() => {
    const initializeDataTable = () => {
      if (!tableRef.current) {
        return;
      }

      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [loading]);

  const handleEliminarJuzgado = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}eliminarjuzgado/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pasar el token correctamente
        },
      });
      setJuzgados(juzgados.filter((juzgado) => juzgado._id !== id));
      navigate("/juzgados");
    } catch (error) {
      console.error("Error al eliminar el juzgado:", error);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <span className="float-end">
              <Link to="/agregar-juzgados" className="btn btn-primary">
                Añadir Juzgado
              </Link>
            </span>
            <h3 className="card-title mb-0">JUZGADOS</h3>
          </div>

          <div className="card-body">
            {loading ? (
              <p>Cargando administración...</p>
            ) : (
              <table className="table table-bordered" ref={tableRef}>
                <thead>
                  <tr>
                    <th>Materia</th>
                    <th>Distrito Judicial</th>
                    <th>Sede</th>
                    <th>Tipo de Juzgado</th>
                    <th>Nombre</th>
                    <th>Numeor de juzgado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {juzgados.map((juzgado) => (
                    <tr key={juzgado._id}>
                      <td>{juzgado.materia}</td>
                      <td>{juzgado.datadistrito[0].nombre}</td>
                      <td>{juzgado.datasede[0]?.sede}</td>
                      <td>{juzgado.datatipo_juzga[0].nombre}</td>
                      <td>{juzgado.nombrejuzgado}</td>
                      <td>{juzgado.njuzgado}</td>
                      <td>
                        <Link
                          to={`/editar-juzgado/${juzgado._id}`}
                          className="btn btn-primary me-2"
                        >
                          <i className="fa-solid fa-pen-to-square"></i> Editar
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleEliminarJuzgado(juzgado._id)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Juzgados;
