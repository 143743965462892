import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

const AddEtapasMateria = () => {
  const [vias, setVias] = useState([]);
  const [viasFiltradas, setViasFiltradas] = useState([]);
  const [nombre, setNombre] = useState("");

  const handleCancel = () => {
    window.history.back();
  };

  const getViaPro = () => {
    axios
      .get(`${window.BACKEND_URL}via_prodi`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setVias(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Periodo:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);
    try {
      const response = await axios.post(
        `${window.BACKEND_URL}etapa_materiapost`,
        formData
      );
      console.log(response.data);
      window.history.back();
    } catch (error) {
      console.error(error);
    }
  };

  const handleMateriaChange = (e) => {
    const materiaId = e.target.value;
    setNombre("");
    // Filtrar vias por la materia seleccionada
    const viasFiltradas = vias.filter((via) => via.materia === materiaId);
    setViasFiltradas(viasFiltradas);
  };

  useEffect(() => {
    getViaPro();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar etapa de materia</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="materia" className="form-label">
                Materia
              </label>
              <br />
              <select
                className="form-select"
                id="materia"
                name="materia"
                onChange={handleMateriaChange}
                required
              >
                <option value="">Seleccione una materia</option>
                <option value="CIVIL">CIVIL</option>
                <option value="CONSTITUCIONAL">CONSTITUCIONAL</option>
                <option value="CONTENCION ADMINISTRATIVA">
                  CONTENCION ADMINISTRATIVA
                </option>
                <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                <option value="LABORAL">LABRORAL</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="viaprocedi" className="form-label">
                Via Procedimental
              </label>
              <br />
              <select
                className="form-select"
                id="viaprocedi"
                name="viaprocedi"
                required
              >
                <option value="">Seleccione un el nombre</option>
                {viasFiltradas.map((via) => (
                  <option key={via._id} value={via._id}>
                    {via.viaprocedi}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="nombre" className="form-label">
                Nombre
              </label>
              <br />
              <input
                type="text"
                id="nombre"
                name="nombre"
                className="form-control"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Guardar
            </button>

            <Link
              to="/etapa_materia"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEtapasMateria;
