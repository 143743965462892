import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs5";
import axios from "axios";

const EntidadAdministrativa = () => {
  const [entidad, setEntiAdmi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tableRef = useRef(null);

  const fetchPeriodos = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}enti_admi`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      console.log(response.data);
      setEntiAdmi(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPeriodos();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current || !$.fn.DataTable) {
        return;
      }

      // Destruir DataTable si ya está inicializado
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      // Inicializar DataTable
      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!isLoading) {
      initializeDataTable();
    }

    // Limpieza al desmontar el componente
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [isLoading]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}enti_admidelete/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      // Actualizar la lista de periodos académicos después de la eliminación
      setEntiAdmi(entidad.filter((periodo) => periodo._id !== id));
    } catch (error) {
      console.error(error);
      // Manejar el error, como mostrar un mensaje de error al usuario
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="float-end">
            <Link to="/agregar-enti_admi" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i> Agregar nuevo dato
            </Link>
          </span>
          <h3 className="card-title mb-0">
            Lista de Entidades Administrativas{" "}
          </h3>
        </div>
        <div className="card-body">
          {isLoading ? (
            <p>Cargando...</p>
          ) : (
            <table className="table table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {entidad.map((entidad) => (
                  <tr key={entidad._id}>
                    <td>{entidad.nombre}</td>
                    <td>
                      <Link
                        to={`/editar-enti_admi/${entidad._id}`}
                        className="btn btn-primary me-2"
                      >
                        <i className="fa-solid fa-pen-to-square"></i> Editar
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(entidad._id)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default EntidadAdministrativa;
