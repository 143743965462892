import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";


const AgregarSede = () => {
    const [nombreSede, setNombreSede] = useState("");
    const [loading, setLoading] = useState(false);
    const [sedeGuardada, setSedeGuardada] = useState(false);
     

    const handleChangeNombreSede = (e) => {
        setNombreSede(e.target.value);
    };

    const handleGrabarSede = async () => {
        setLoading(true);
        try {
            // Lógica para guardar el dato
            await axios.post(`${window.BACKEND_URL}agregarsede`, { nombre: nombreSede }, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            setSedeGuardada(true);
            
            // Redirigir a la página de sede-cursos después de guardar exitosamente la sede
            window.location.href = "sede-curso";
        } catch (error) {
            console.error("Error al guardar la sede:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelar = () => {
        // Aquí puedes implementar la lógica para cancelar la operación de agregar sede
        // Por ahora, simplemente limpiaremos el campo del nombre de la sede
        setNombreSede("");
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">AGREGAR SEDE DE CURSO</h5>
                    </div>
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="nombreSede" className="form-label">Nombre de la sede:</label>
                            <input 
                                type="text" 
                                id="nombreSede" 
                                className="form-control" 
                                value={nombreSede} 
                                onChange={handleChangeNombreSede} 
                                disabled={loading} // Deshabilitar el input mientras se está cargando
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button 
                                className="btn btn-primary me-2" 
                                onClick={handleGrabarSede} 
                                disabled={loading || nombreSede.trim() === ""} // Deshabilitar el botón si está cargando o si no se ha ingresado un nombre de sede
                            >
                                Grabar
                            </button>
                            <Link to="/sede-curso"
                                className="btn btn-secondary"  
                                disabled={loading}
                            >
                                Cancelar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgregarSede;
