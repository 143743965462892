import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";

const Titulos = () => {
    const [titulos, setTitulos] = useState([]);
    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${window.BACKEND_URL}titulo`, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            setTitulos(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${window.BACKEND_URL}titulodelate/${id}`, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            // Actualizar la lista de títulos después de la eliminación
            fetchData();
        } catch (error) {
            console.error("Error al eliminar el título:", error);
        }
    };

    useEffect(() => {
        const initializeDataTable = async () => {
            if (!tableRef.current) {
                return;
            }

            // Limpiar DataTable si ya está inicializado
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }

            // Inicializar DataTable
            $(tableRef.current).DataTable({
                language: {
                    url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
                },
            });
        };

        fetchData();
        initializeDataTable();

        // Limpieza al desmontar el componente
        return () => {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }
        };
    }, []);

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <span className="float-end">
                        <Link to="/admin/agregar-titulo" className="btn btn-primary">
                            <i className="fa-solid fa-plus"></i> Agregar título de curso
                        </Link>
                    </span>
                    <h3 className="card-title mb-0">Listado de cursos</h3>
                </div>
                <div className="card-body">
                    {loading ? (
                        <p>Cargando...</p>
                    ) : (
                        <table className="table table-bordered" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Titulo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {titulos.map((titulo) => (
                                    <tr key={titulo._id}>
                                        <td>{titulo.nombre}</td>
                                        <td>
                                            <Link
                                                to={`/admin/editar-titulo/${titulo._id}`}
                                                className="btn btn-primary me-2"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i>{" "}
                                                Editar
                                            </Link>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleDelete(titulo._id)}
                                            >
                                                Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
};

export default Titulos;
