import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import FullCalendar from "@fullcalendar/react"; // Importa el componente FullCalendar
import esLocale from "@fullcalendar/core/locales/es";

import dayGridPlugin from "@fullcalendar/daygrid";

const Calendario = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [sedes, setSede] = useState([]);
  const [docentes, setDocentes] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [selectedSede, setSelectedSede] = useState("");
  const [selectedDocente, setSelectedDocente] = useState("");
  const [selectedCurso, setSelectedCurso] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const sedes = await axios.get(`${window.BACKEND_URL}sedelist`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      setSede(sedes.data);
      const docentes = await axios.get(`${window.BACKEND_URL}users/docente`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      setDocentes(docentes.data);
      const response = await axios.get(
        `${window.BACKEND_URL}cursoscalendaradmin`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCursos(response.data);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Datos seleccionados", {
      selectedSede,
      selectedDocente,
      selectedCurso,
    });

    const audiencias = cursos
      .filter(
        (curso) =>
          curso.sedecurso_id === selectedSede &&
          curso.docente_id === selectedDocente &&
          curso._id === selectedCurso
      )
      .reduce((allAudiencias, curso) => {
        if (curso.audiencia) {
          allAudiencias.push(...curso.audiencia);
        }
        return allAudiencias;
      }, []);
    const events = audiencias.map((audiencia) => ({
      title: audiencia.tipo_audi,
      start: audiencia.fechaHoraInicio,
      end: audiencia.fechaHoraFin,
      datacurso: audiencia.curso,
      link_audi: audiencia.link_audi,
      data_seccion: audiencia.seccion,
    }));

    setEvents(events);
  }, [selectedCurso, selectedDocente, selectedSede]);

  const [clickedEvent, setClickedEvent] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleEventClick = (info) => {
    if (clickedEvent && clickedEvent.id === info.event.id) {
      setClickedEvent(null);
    } else {
      setClickedEvent(info.event);
      const rect = info.el.getBoundingClientRect();
      const scroll = window.pageYOffset;
      setModalPosition({
        top: rect.top + scroll - 410,
        left: rect.right - 290,
      });
    }
  };

  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // La hora '0' debe ser '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Calendario de Audiencia</h5>
              <div className="mb-3">
                <label>Sede:</label>
                <select
                  type="text"
                  className="form-select"
                  name="sede"
                  onChange={(e) => setSelectedSede(e.target.value)}
                >
                  <option value="">Selecciona una Sede:</option>
                  {sedes.map((sede) => (
                    <option key={sede._id} value={sede._id}>
                      {sede.nombre}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label>Docente:</label>
                <select
                  type="text"
                  className="form-select"
                  name="docente"
                  onChange={(e) => setSelectedDocente(e.target.value)}
                >
                  <option value="">Selecciona un Docente:</option>

                  {docentes
                    .filter((docente) => docente.role === "Docente")
                    .map((docente) => (
                      <option key={docente._id} value={docente._id}>
                        {docente.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3">
                <label>Curso:</label>

                <select
                  type="text"
                  className="form-select"
                  name="curso"
                  onChange={(e) => setSelectedCurso(e.target.value)}
                >
                  <option value="">Selecciona un Curso:</option>

                  {cursos
                    .filter((curso) => curso.docente_id === selectedDocente)
                    .map((curso) => (
                      <option key={curso._id} value={curso._id}>
                        {curso.informacion_titulo.nombre} {curso.seccion}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">CALENDARIO</h5>
            <FullCalendar
              plugins={[dayGridPlugin]}
              locale={esLocale}
              headerToolbar={{
                left: "title",
                right: "today prev,next",
              }}
              eventClick={handleEventClick}
              themeSystem="bootstrap"
              dayHeaderFormat={{ weekday: "long" }}
              events={events} // Los eventos que se mostrarán en el calendario
              eventClassNames="custom-event"
            />
            {clickedEvent && (
              <div
                className=" bg-white p-2 shadow border-4 rounded-3"
                style={{
                  top: modalPosition.top,
                  left: modalPosition.left,
                  zIndex: 9999,
                  position: "absolute",
                }}
              >
                <div>
                  <h4>{clickedEvent.title}</h4>{" "}
                  {`${formatTime(
                    new Date(clickedEvent.startStr)
                  )} - ${formatTime(new Date(clickedEvent.endStr))} `}
                </div>{" "}
                <h4>{clickedEvent._def.extendedProps.datacurso}</h4> <br />{" "}
                <div>
                  <a
                    href={clickedEvent._def.extendedProps.link_audi}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link de la reunion
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendario;
