import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Addtitle = () => {
  const [titulocursos, setTitulocursos] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${window.BACKEND_URL}titulopost`,
        { nombre: titulocursos },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log("Response:", response.data);

      // Mostrar mensaje de éxito
      alert("El título se agregó correctamente.");

      // Restablecer el formulario y volver atrás en el historial
      setTitulocursos("");
      setIsSubmitting(false);
      window.history.back();
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      window.history.back();
      setIsSubmitting(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Agregar título</h5>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label htmlFor="titulocursos" className="form-label">
            Nombre del Título:
          </label>
          <input
            type="text"
            id="titulocursos"
            className="form-control"
            value={titulocursos}
            onChange={(e) => setTitulocursos(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary me-2"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Ya se envio ->" : "Grabar"}
          </button>
          <Link to="/admin/titulo-decursos" className="btn btn-secondary">
            Cancelar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Addtitle;
