import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const AgregarCuaderno = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const forms = document.querySelectorAll("form");
    // Iterar sobre cada formulario
    const formData = Object.fromEntries(new FormData(forms[0]).entries());

    console.log("Datos de todos los formularios:", formData);
    try {
      // Lógica para guardar el cuaderno
      await axios.post(`${window.BACKEND_URL}agregartipocuaderno`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      // Redirigir a la página de cuadernos después de guardar exitosamente el cuaderno
      window.location.href = "tipo-cuaderno";
    } catch (error) {
      console.error("Error al guardar el cuaderno:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">AGREGAR CUADERNO</h5>
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Nombre del cuaderno:</label>
                  <input type="text" name="nombre" className="form-control" />
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary me-2" type="submit">
                    Grabar
                  </button>
                  <Link to="/tipo-cuaderno" className="btn btn-secondary">
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgregarCuaderno;
