const Contact = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Empty card</h5>
        </div>
        <div className="card-body">




        </div>
      </div>
    </>
  );
};

export default Contact;
