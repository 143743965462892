import { Outlet, Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Layout = () => {
  const [username] = useState(sessionStorage.getItem("userName"));
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <nav id="sidebar" className="sidebar">
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="/">
            <span className="align-middle me-3">SIMULADOR</span>
          </a>
          <ul className="sidebar-nav">
            <li className="sidebar-item">
              <a
                data-bs-target="#pages1"
                data-bs-toggle="collapse"
                className="sidebar-link collapsed"
                aria-expanded="false"
              >
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Usuarios</span>
              </a>
              <ul
                id="pages1"
                className="sidebar-dropdown list-unstyled collapse"
                data-bs-parent="#sidebar"
              >
                <li className="sidebar-item">
                  <Link to="/lista-de-usuarios" className="sidebar-link">
                    <span className="align-middle">Administrador</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/lista-de-Docente" className="sidebar-link">
                    <span className="align-middle">Docente</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/lista-de-Alumnos" className="sidebar-link">
                    <span className="align-middle">Alumnos</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="sidebar-item">
              <a
                data-bs-target="#pages2"
                data-bs-toggle="collapse"
                className="sidebar-link collapsed"
                aria-expanded="false"
              >
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Administración académica</span>
              </a>
              <ul
                id="pages2"
                className="sidebar-dropdown list-unstyled collapse"
                data-bs-parent="#sidebar"
              >
                <li className="sidebar-item">
                  <Link to="/turnos" className="sidebar-link">
                    <span className="align-middle">Turno</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/admin/titulo-decursos" className="sidebar-link">
                    <span className="align-middle">Titulos de cursos</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/sede-curso" className="sidebar-link">
                    <span className="align-middle">Sedes de Cursos</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/cursos" className="sidebar-link">
                    <span className="align-middle">Cursos</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/periodo-academico" className="sidebar-link">
                    <span className="align-middle">Periodo academico</span>
                  </Link>
                </li>
                {/* <li className="sidebar-item">
                  <Link to="/materia" className="sidebar-link">
                    <span className="align-middle">Materia</span>
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className="sidebar-item">
              <a
                data-bs-target="#pages3"
                data-bs-toggle="collapse"
                className="sidebar-link collapsed d-flex align-items-center"
                aria-expanded="false"
              >
                <i className="align-middle me-2" data-feather="list"></i>
                <span className="align-center">
                  Administración de <br /> Expedientes
                </span>
              </a>
              <ul
                id="pages3"
                className="sidebar-dropdown list-unstyled collapse"
                data-bs-parent="#sidebar"
              >
                <li className="sidebar-item">
                  <Link to="/tipo-cuaderno" className="sidebar-link">
                    <span className="align-middle">Tipos de Cuaderno </span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/pretensiones" className="sidebar-link">
                    <span className="align-middle">Pretensiones</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/distrito-judicial" className="sidebar-link">
                    <span className="align-middle">Distrito judicial</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/sede_judicial" className="sidebar-link">
                    <span className="align-middle">Sedes</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/tipo_juzgado" className="sidebar-link">
                    <span className="align-middle">Tipo de juzgado</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/juzgados" className="sidebar-link">
                    <span className="align-middle">Juzgados</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/salas" className="sidebar-link">
                    <span className="align-middle">Sala</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/sala_suprema" className="sidebar-link">
                    <span className="align-middle">Sala suprema</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/via_prodi" className="sidebar-link">
                    <span className="align-middle">Via procedimental</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/etapa_materia" className="sidebar-link">
                    <span className="align-middle">Etapas de material</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/enti_admi" className="sidebar-link">
                    <span className="align-middle">Entidad administrativa</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/urp" className="sidebar-link">
                    <span className="align-middle">URP</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="sidebar-item">
              <Link to="/Calendarioaudiencias" className="sidebar-link">
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Calendario de Audiencias</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/estadistica" className="sidebar-link">
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Estadísticas</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="main">
        <nav className="navbar navbar-expand navbar-light navbar-bg">
          <a className="sidebar-toggle">
            <i className="hamburger align-self-center"></i>
          </a>
          <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align">
              <li className="nav-item dropdown">
                <a
                  className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="align-middle" data-feather="settings"></i>
                </a>

                <a
                  className="nav-link dropdown-toggle d-none d-sm-inline-block"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="img/avatars/avatar.jpg"
                    className="avatar img-fluid rounded-circle me-1"
                    alt="Chris Wood"
                  />{" "}
                  <span className="text-dark">{username}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="pages-profile.html">
                    <i className="align-middle me-1" data-feather="user"></i>{" "}
                    Profile
                  </a>
                  <a className="dropdown-item" href="#">
                    <i
                      className="align-middle me-1"
                      data-feather="pie-chart"
                    ></i>{" "}
                    Analytics
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="pages-settings.html">
                    Settings & Privacy
                  </a>
                  <a className="dropdown-item" href="#">
                    Help
                  </a>
                  <span className="dropdown-item" onClick={handleLogout}>
                    Sign out
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <main className="content">
          <div className="container-fluid p-0">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;
