import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";

const EtapasMateria = () => {
  const [etapas, setEtapas] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);

  const fetchEtapas = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}etapa_materia`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      setEtapas(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}etapa_materiadelete/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });
      // Actualizar la lista de turnos después de eliminar el turno
      setEtapas(etapas.filter((datas) => datas._id !== id));
    } catch (error) {
      console.error("Error deleting turno:", error);
    }
  };

  useEffect(() => {
    fetchEtapas();
  }, []);

  useEffect(() => {
    const initializeDataTable = () => {
      if (!tableRef.current) {
        return;
      }

      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [loading]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="float-end">
            <Link to="/agregar-etapa_materia" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i> Agregar nuevo dato
            </Link>
          </span>
          <h3 className="card-title mb-0">Lista de Etapas de materia</h3>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <table className="table table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th>Materia</th>
                  <th>Via Procedimental</th>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {etapas.map((datas) => (
                  <tr key={datas._id}>
                    <td>{datas.materia}</td>
                    <td>{datas.informacion_viapro[0].viaprocedi}</td>
                    <td>{datas.nombre}</td>
                    <td>
                      <Link
                        to={`/editar-etapa_materia/${datas._id}`}
                        className="btn btn-primary me-2"
                      >
                        <i className="fa-solid fa-pen-to-square me-2"></i>
                        Editar
                      </Link>
                      <button
                        className="btn  btn-danger"
                        onClick={() => handleDelete(datas._id)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default EtapasMateria;
